import { ApiService } from '@checkout/services/core';
import type { NuxtAppOptions } from '@nuxt/types';
import type { ApiClientMethods } from '@vue-storefront/core';

import type {
  CheckoutApiMethods,
  SetBillingAddressOnCartInput,
  SetBillingAddressOnCartMutation,
  SetShippingAddressesOnCartInput,
  SetShippingAddressOnCartMutation
} from '~/models';

export class ShippingAddressesService extends ApiService<ApiClientMethods<CheckoutApiMethods>> {
  constructor(context: NuxtAppOptions) {
    super(context.$vsf.$magento.api);
  }

  setShippingAddresses(
    input: SetShippingAddressesOnCartInput,
    customQuery = { setShippingAddressesOnCart: 'customSetShippingAddressesOnCart' }
  ): Promise<SetShippingAddressOnCartMutation> {
    return this.handleApiResponse(this.api.setShippingAddressesOnCart(input, customQuery));
  }

  setBillingAddressOnCart(input: SetBillingAddressOnCartInput, customQuery = null): Promise<SetBillingAddressOnCartMutation> {
    return this.handleApiResponse(this.api.setBillingAddressOnCart(input, customQuery));
  }
}
