import { useShippingAddress } from '@checkout/composables/useShippingAddress';
import { addressTypeMap } from '@checkout/composables/useUserAddress/models';
import {
  transformAvailableRegionToRegion,
  transformCartAddressRegionToRegion
} from '@checkout-organisms/shipping-step/_internal/shipping-address-form/transformers';
import type { AddressForm } from '@checkout-organisms/shipping-step/models';

import type { Address, AddressInput, AddressType, CartShippingAddress, CartShippingAddressInput } from '~/models';
import type { CountryCodeEnum } from '~/models';
import { AddressTypeEnum } from '~/models';

export const useAddressTransformer = () => {
  const { getRegion } = useShippingAddress();

  const addressFormToAddressInput = (address: AddressForm, addressType: AddressType, isDefault?: boolean): AddressInput => {
    const region = address.country_code && address.region_code ? getRegion(address.country_code, address.region_code, true) : null;

    return {
      city: address.city,
      firstname: address.firstname,
      lastname: address.lastname,
      postcode: address.postcode || null,
      street: [address.street, address.apartment].filter(Boolean),
      telephone: address.telephone || '',
      country_code: address.country_code || undefined,

      region: region ? transformAvailableRegionToRegion(region) : null,
      firstnamekana: address.firstnamekana || null,
      lastnamekana: address.lastnamekana || null,
      address_book_type: addressTypeMap[addressType],

      default_shipping: isDefault && addressType === AddressTypeEnum.SHIPPING,
      default_billing: isDefault && addressType === AddressTypeEnum.BILLING
    };
  };

  const cartAddressToAddress = (address: CartShippingAddress, addressType: AddressType): Address => {
    return {
      firstname: address.firstname,
      lastname: address.lastname,
      firstnamekana: address.firstnamekana ?? null,
      lastnamekana: address.lastnamekana ?? null,
      city: address.city,
      postcode: address.postcode ?? null,
      street: address.street ?? [],
      telephone: address.telephone ?? null,
      country_code: address.country.code as CountryCodeEnum,
      region: transformCartAddressRegionToRegion(address.region) || null,
      address_book_type: addressType
    };
  };

  const addressToAddressForm = (address: Address): AddressForm => {
    return {
      firstname: address.firstname,
      lastname: address.lastname,
      telephone: address.telephone || '',
      street: address.street?.[0] || '',
      apartment: address.street?.[1] || '',
      postcode: address.postcode || '',
      city: address.city,
      country_code: address.country_code || '',
      region_code: address.region?.region_code || '',
      firstnamekana: address.firstnamekana || '',
      lastnamekana: address.lastnamekana || '',
      default_shipping: address.default_shipping || false,
      default_billing: address.default_billing || false
    };
  };

  const addressToCartAddressInput = (address: Address): CartShippingAddressInput => {
    return {
      address: {
        firstname: address.firstname,
        lastname: address.lastname,
        city: address.city,
        street: address.street || [],
        telephone: address.telephone || '',
        postcode: address.postcode || undefined,
        country_code: address.country_code,
        firstnamekana: address.firstnamekana || undefined,
        lastnamekana: address.lastnamekana || undefined,
        region_id: address.region?.region_id,
        save_in_address_book: false
      }
    };
  };

  const cartAddressToCartShippingAddressInput = (address: CartShippingAddress): CartShippingAddressInput => {
    return {
      address: {
        firstname: address.firstname,
        lastname: address.lastname,
        firstnamekana: address.firstnamekana || null,
        lastnamekana: address.lastnamekana || null,
        street: address.street || [],
        city: address.city,
        postcode: address.postcode || null,
        telephone: address.telephone || null,
        region_id: address.region?.region_id || null,
        country_code: address.country.code as CountryCodeEnum
      }
    };
  };

  return {
    addressFormToAddressInput,
    cartAddressToAddress,
    addressToAddressForm,
    addressToCartAddressInput,
    cartAddressToCartShippingAddressInput
  };
};
