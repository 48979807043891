



















































import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'AccordionItem',

  props: {
    title: {
      type: String,
      required: true
    },
    anchorId: {
      type: String,
      default: ''
    },
    static: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    expanded: {
      type: Boolean
    },
    id: {
      type: Number,
      required: true
    },
    inactive: {
      type: Boolean
    }
  },
  emits: ['toggle'],
  setup(props, { emit }) {
    const headerId = computed(() => `accordion-header-${props.id}`);
    const panelId = computed(() => `accordion-panel-${props.id}`);

    const onHeaderClick = () => {
      if (props.disabled || props.inactive) return;

      emit('toggle');
    };

    return { onHeaderClick, headerId, panelId };
  }
});
