import { defineStore } from 'pinia';

import type { TransformedCountry } from '~/models';

import type { CountriesState } from './countriesState.model';

export const useCountriesStore = defineStore('countries-v2', {
  state: (): CountriesState => ({
    allCountries: [],
    allowedCountries: {
      store: null,
      countries: []
    }
  }),
  persist: true,
  actions: {
    setAllCountries(countries: TransformedCountry[]): void {
      this.allCountries = countries;
    },

    setAllowedCountries(store: string, countries: TransformedCountry[]): void {
      this.allowedCountries = {
        store,
        countries
      };
    }
  },
  getters: {
    getCountryById:
      (state) =>
      (id: string): TransformedCountry => {
        if (!state.allowedCountries.countries.length) return null;

        return state.allowedCountries.countries.find((country) => country.abbreviation === id) || null;
      }
  }
});
