


















































import type { PropType } from '@nuxtjs/composition-api';
// eslint-disable no-duplicate-imports
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfImage } from '@storefront-ui/vue';

import { useConfig } from '~/composables';
import { useBooxi } from '~/diptyqueTheme/composable/useBooxi';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import cartGetters from '~/helpers/getters/cartGetters';
import productGetters from '~/helpers/getters/productGetters';
import type { CartItem, Product, ProductImage } from '~/models';

export default defineComponent({
  name: 'OrderSummaryProduct',
  components: {
    SfImage,
    VaimoBadge: () => import('~/diptyqueTheme/components/atoms/VaimoBadge.vue'),
    VaimoEngravingCard: () =>
      import(
        '~/diptyqueTheme/components/organisms/cart/parts/VaimoEngravingCard.vue'
      ),
    VaimoBooxiLifeTimeLabel: () =>
      import(
        '~/diptyqueTheme/components/organisms/cart/parts/VaimoBooxiLifeTimeLabel.vue'
      )
  },
  props: {
    cartItem: {
      type: Object as PropType<CartItem>,
      required: true
    }
  },
  setup(props) {
    const product = computed<Product | null>(() =>
      cartGetters.getProduct(props.cartItem)
    );
    const image = computed<ProductImage>(() =>
      productGetters.getImage(product.value)
    );
    const quantity = computed<number>(() =>
      cartGetters.getQuantity(props.cartItem)
    );
    const variantName = computed<string>(() =>
      productGetters.getVariantName(product.value)
    );
    const capacity = computed<string>(() =>
      productGetters.getCapacity(product.value)
    );
    const isPreOrder = computed(() => productGetters.isPreOrder(product.value));
    const isValidProductVariantName = computed(() =>
      productGetters.isValidProductVariantName(product.value)
    );
    const price = computed<string>(() => {
      const productPrice = productGetters.getPrice(product.value).regular;

      return productPrice ? validateFraction(productPrice) : '';
    });

    // Engraving
    const { config } = useConfig();
    const isSampleProduct = computed<boolean>(() => {
      const productSku = productGetters.getSku(product.value);
      const xmasSkus = config.value?.xmas_skus;

      if (Array.isArray(xmasSkus) && xmasSkus.includes(productSku)) {
        return false;
      }

      return productGetters.getPrice(product.value).regular === 0;
    });

    const isEngraving = computed<boolean>(
      () => !!cartGetters.getEngraving(props.cartItem) && !isSampleProduct.value
    );

    // Booxi
    const { booxiLifeTimeData } = useBooxi();

    const isBooxiProduct = computed<boolean>(
      () =>
        booxiLifeTimeData.value.isAutoCloseEnabled &&
        cartGetters.getIsVirtualCartItem(props.cartItem) &&
        !!booxyLifetime.value
    );

    const booxyLifetime = computed<string | null>(
      () => cartGetters.getBooxiLifetime(props.cartItem) || null
    );

    return {
      product,
      image,
      isSampleProduct,
      isEngraving,
      booxyLifetime,
      isBooxiProduct,
      quantity,
      price,
      isValidProductVariantName,
      variantName,
      capacity,
      isPreOrder
    };
  }
});
