import { ApiService } from '@checkout/services/core';
import type { NuxtAppOptions } from '@nuxt/types';
import type { CustomQuery } from '@vue-storefront/core';

import customerPaymentTokensQuery from '~/diptyqueTheme/modules/gmo-payment/queries/customerPaymentTokens';
import deletePaymentTokenMutation from '~/diptyqueTheme/modules/gmo-payment/queries/deletePaymentToken';
import paymentMethodsQuery from '~/diptyqueTheme/modules/gmo-payment/queries/paymentMethods';
import paymentStatusQuery from '~/diptyqueTheme/modules/gmo-payment/queries/paymentStatus';
import threeDSecureReturnMutation from '~/diptyqueTheme/modules/gmo-payment/queries/threeDSecureReturn';
import type {
  CheckoutApiMethods,
  DeleteGmoPaymentTokenInput,
  DeleteGmoPaymentTokenMutation,
  GetAvailablePaymentMethodsInput,
  GetAvailablePaymentMethodsQuery,
  GetGmoCustomerPaymentTokensQuery,
  GetGmoPaymentMethodsInput,
  GetGmoPaymentMethodsQuery,
  GetGmoPaymentStatusInput,
  GetGmoPaymentStatusQuery,
  SetPaymentMethodOnCartInput,
  SetPaymentMethodOnCartMutation,
  VerifyGmo3dSecureInput,
  VerifyGmo3dSecureMutation
} from '~/models';
import type { ApiClientMethods } from '~/types/core';

export class PaymentService extends ApiService<ApiClientMethods<CheckoutApiMethods>> {
  constructor(context: NuxtAppOptions) {
    super(context.$vsf.$magento.api);
  }

  setPaymentMethod(
    input: SetPaymentMethodOnCartInput,
    customQuery = { setPaymentMethodOnCart: 'customSetPaymentMethodOnCart' }
  ): Promise<SetPaymentMethodOnCartMutation> {
    return this.handleApiResponse(this.api.setPaymentMethodOnCart(input, customQuery));
  }

  getAvailablePaymentMethods(input: GetAvailablePaymentMethodsInput, customQuery?: CustomQuery): Promise<GetAvailablePaymentMethodsQuery> {
    return this.handleApiResponse(this.api.getAvailablePaymentMethods(input, customQuery));
  }

  getGmoPaymentMethods(params: GetGmoPaymentMethodsInput): Promise<GetGmoPaymentMethodsQuery> {
    return this.handleApiResponse(
      this.api.customQuery({
        query: paymentMethodsQuery,
        queryVariables: params
      })
    );
  }

  getGmoCustomerPaymentTokens(): Promise<GetGmoCustomerPaymentTokensQuery> {
    return this.handleApiResponse(
      this.api.customQuery({
        query: customerPaymentTokensQuery
      })
    );
  }

  deletePaymentToken(input: DeleteGmoPaymentTokenInput): Promise<DeleteGmoPaymentTokenMutation> {
    return this.handleApiResponse(this.api.customMutation({ mutation: deletePaymentTokenMutation, mutationVariables: input }));
  }

  getGmoPaymentStatus(input: GetGmoPaymentStatusInput): Promise<GetGmoPaymentStatusQuery> {
    return this.handleApiResponse(
      this.api.customQuery({
        query: paymentStatusQuery,
        queryVariables: input
      })
    );
  }

  verifyGmo3dSecure(input: VerifyGmo3dSecureInput): Promise<VerifyGmo3dSecureMutation> {
    return this.handleApiResponse(
      this.api.customMutation({
        mutation: threeDSecureReturnMutation,
        mutationVariables: input
      })
    );
  }
}
