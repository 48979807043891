import { useCartStore, useCheckoutStore } from '@checkout/stores';
import { useCountriesStore } from '@checkout/stores';
import { computed } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';

import { useStore } from '~/composables';
import type { CartShippingAddress, Country } from '~/models';

export function useShippingComplete() {
  const checkoutStore = useCheckoutStore();
  const { shippingData } = storeToRefs(checkoutStore);
  const { isJpStore, isUsCaStore, isZhHkStore } = useStore();
  const countriesStore = useCountriesStore();
  const { getCountryById } = storeToRefs(countriesStore);
  const cartStore = useCartStore();
  const { hasOnlyBooxiItemsInCart, billingAddress } = storeToRefs(cartStore);

  const getCountryLabel = (countryCode: string): string => {
    if (!countryCode) {
      return '';
    }

    const country = getCountryById.value(countryCode);
    return country?.label || countryCode;
  };

  const getAddressParts = (address: CartShippingAddress): string[] => {
    if (!address) return [];

    const {
      firstname = '',
      lastname = '',
      firstnamekana = '',
      lastnamekana = '',
      country = {} as Country,
      street = [],
      postcode = '',
      region = {},
      city = '',
      telephone = ''
    } = address;

    let fullName = [firstname, lastname].filter(Boolean).join(' ');

    if (isJpStore.value) {
      fullName = [lastname, firstname].filter(Boolean).join(' ');
    }

    const kanaName = [firstnamekana, lastnamekana].filter(Boolean).join(' ');
    const countryName = getCountryLabel(country.code || '') || country.label || '';
    const streetAddress = street.filter(Boolean).join(' ');

    const formatLocation = (parts: string[]): string => parts.filter(Boolean).join(' ');

    const formatJP = (): string[] => {
      const locationParts = formatLocation([postcode, region.label, city, streetAddress]);
      return [fullName, kanaName, locationParts].filter(Boolean);
    };

    const formatUSCA = (): string[] => {
      const locationParts = formatLocation([streetAddress, postcode, city, region.label]);
      return [countryName, fullName, locationParts].filter(Boolean);
    };

    const formatZHHK = (): string[] => {
      const locationParts = formatLocation([streetAddress, city]);
      return [fullName, locationParts, countryName].filter(Boolean);
    };

    const formatDefault = (): string[] => {
      const locationParts = formatLocation([streetAddress, postcode, city]);
      return [fullName, locationParts, countryName].filter(Boolean);
    };

    const regionFormatters = new Map([
      [isJpStore.value, formatJP],
      [isUsCaStore.value, formatUSCA],
      [isZhHkStore.value, formatZHHK]
    ]);
    const formatAddress = regionFormatters.get(true) || formatDefault;

    return [...formatAddress(), telephone].filter(Boolean);
  };

  const selectedShippingAddress = computed<string>(() => {
    if (!shippingData.value && !billingAddress.value) return '';

    const parts: string[] = hasOnlyBooxiItemsInCart.value ? getAddressParts(billingAddress.value) : getAddressParts(shippingData.value);
    return parts.join(', ');
  });

  const selectedShippingMethod = computed<string>(() => {
    const method = shippingData.value?.selected_shipping_method;

    return !method || hasOnlyBooxiItemsInCart.value ? '' : method.method_title;
  });

  return {
    selectedShippingAddress,
    selectedShippingMethod
  };
}
