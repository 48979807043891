
















































































import { CartTotalsSectionsEnum } from '@checkout-organisms/order-summary/models';
import type { PropType } from '@nuxtjs/composition-api';
// eslint-disable-next-line no-duplicate-imports
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfProperty } from '@storefront-ui/vue';

import { useConfig } from '~/composables';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import cartGetters from '~/helpers/getters/cartGetters';
import type { CartPrices } from '~/models';
import { checkIsNumberType } from '~/modules/core/helpers';

type CartTotalsSectionsType =
  typeof CartTotalsSectionsEnum[keyof typeof CartTotalsSectionsEnum];

export default defineComponent({
  name: 'CartTotals',
  components: {
    SfProperty,
    Taxes: () => import('molecules/Taxes.vue'),
    Fees: () => import('molecules/Fees.vue'),
    CodFee: () => import('molecules/CodFee.vue')
  },
  props: {
    cartPrices: {
      type: Object as PropType<CartPrices>,
      required: true
    },
    shippingPrice: {
      type: Number,
      default: null
    },
    hideSections: {
      type: Array as PropType<CartTotalsSectionsType[]>,
      default: () => []
    },
    signatureGiftBox: {
      type: Number,
      default: null
    },
    hasWelcomeGift: {
      type: Boolean
    }
  },
  setup(props) {
    const { config } = useConfig();

    const totalPrice = computed(
      () =>
        cartGetters.getTotals(
          props.cartPrices,
          config.value?.tax_cart_display_subtotal || ''
        )?.total
    );

    const subtotalPrice = computed(
      () =>
        cartGetters.getTotals(
          props.cartPrices,
          config.value?.tax_cart_display_subtotal || ''
        )?.subtotal
    );

    const isTaxDetailsEnabled = computed(
      () =>
        cartGetters.getTaxes(props.cartPrices) &&
        !!config.value?.tax_display_is_tax_details_display
    );

    const isFeesExist = computed(() => cartGetters.getFees(props.cartPrices));

    const totalDiscountValue = computed(() =>
      cartGetters.getDiscountAmount(props.cartPrices)
    );

    const codFeeAmount = computed(() =>
      cartGetters.getCodFeesAmount(props.cartPrices)
    );

    const isSectionAllowed = (section: CartTotalsSectionsType) => {
      return !props.hideSections.includes(section);
    };

    return {
      totalPrice,
      subtotalPrice,
      totalDiscountValue,
      codFeeAmount,
      isTaxDetailsEnabled,
      isFeesExist,
      validateFraction,
      isSectionAllowed,
      CartTotalsSectionsEnum,
      checkIsNumberType
    };
  }
});
