var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-summary-totals"},[(
      _vm.isSectionAllowed(_vm.CartTotalsSectionsEnum.SUBTOTAL_PRICE) &&
      _vm.checkIsNumberType(_vm.subtotalPrice)
    )?_c('SfProperty',{staticClass:"sf-property--full-width",attrs:{"name":_vm.$t('checkout.order_summary.subtotal'),"value":_vm.validateFraction(_vm.subtotalPrice)}}):_vm._e(),_vm._v(" "),(
      _vm.isSectionAllowed(_vm.CartTotalsSectionsEnum.TAXES) && _vm.isTaxDetailsEnabled
    )?_c('Taxes'):_vm._e(),_vm._v(" "),(_vm.isSectionAllowed(_vm.CartTotalsSectionsEnum.FEES) && _vm.isFeesExist)?_c('Fees'):_vm._e(),_vm._v(" "),(
      _vm.isSectionAllowed(_vm.CartTotalsSectionsEnum.DISCOUNT) && _vm.totalDiscountValue
    )?_c('SfProperty',{staticClass:"sf-property--full-width property__secondary",attrs:{"name":_vm.$t('checkout.order_summary.discount'),"value":("-" + (_vm.validateFraction(_vm.totalDiscountValue)))}}):_vm._e(),_vm._v(" "),(
      _vm.isSectionAllowed(_vm.CartTotalsSectionsEnum.SHIPPING_PRICE) &&
      _vm.checkIsNumberType(_vm.shippingPrice)
    )?_c('SfProperty',{staticClass:"sf-property--full-width property__secondary",attrs:{"name":_vm.$t('checkout.order_summary.shipping'),"value":_vm.shippingPrice
        ? _vm.validateFraction(_vm.shippingPrice)
        : _vm.$t('common.price.free')}}):_vm._e(),_vm._v(" "),(_vm.isSectionAllowed(_vm.CartTotalsSectionsEnum.COD_FEES) && _vm.codFeeAmount)?_c('CodFee'):_vm._e(),_vm._v(" "),(
      _vm.isSectionAllowed(_vm.CartTotalsSectionsEnum.SIGNATURE_GIFT_BOX) &&
      _vm.checkIsNumberType(_vm.signatureGiftBox)
    )?_c('SfProperty',{staticClass:"sf-property--full-width property__secondary",attrs:{"name":_vm.$t('checkout.order_summary.signature_gift_box'),"value":_vm.signatureGiftBox
        ? _vm.validateFraction(_vm.signatureGiftBox)
        : _vm.$t('common.price.free')}}):_vm._e(),_vm._v(" "),(
      _vm.isSectionAllowed(_vm.CartTotalsSectionsEnum.WELCOME_GIFT) && _vm.hasWelcomeGift
    )?_c('SfProperty',{staticClass:"sf-property--full-width property__secondary",attrs:{"name":_vm.$t('checkout.order_summary.welcome_gift'),"value":_vm.$t('common.price.free')}}):_vm._e(),_vm._v(" "),_c('SfProperty',{staticClass:"sf-property--full-width uppercase mt-sm total",attrs:{"name":_vm.$t('checkout.order_summary.total'),"value":_vm.subtotalPrice && !_vm.totalPrice
        ? _vm.$t('common.price.free')
        : _vm.validateFraction(_vm.totalPrice)}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }