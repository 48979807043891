import { useEmailRecognitionEvents } from '@checkout/composables';
import type { CreateUserForm } from '@checkout-organisms/auth/registration-form/models';
import type { Field } from '@form-generator';
import { useFields } from '@form-generator';
import { computed, ref, useContext } from '@nuxtjs/composition-api';

import { useOptins } from '~/composables';

export const useRegistrationForm = () => {
  const { i18n } = useContext();

  const { optins, areOptinsCheckedByDefault } = useOptins();

  const formData = ref<CreateUserForm>({
    email: '',
    password: '',
    confirmedPassword: '',
    remember_me: true,
    optin_newsletter: areOptinsCheckedByDefault.value,
    optin_sms: areOptinsCheckedByDefault.value,
    optin_mail: areOptinsCheckedByDefault.value
  });

  const { extendField, getEmailField, getPasswordField, getCheckbox } = useFields();

  const { isEmailRecognized, showEmailRecognitionMessage, getEmailRecognitionEvents, eventsExtraParams } = useEmailRecognitionEvents();

  const formFields = computed<Field[]>(() => [
    extendField(getEmailField('email'), {
      attributes: {
        autocomplete: 'username',
        class: 'input--with-label'
      },
      options: {
        eventsExtraParams
      },
      events: {
        ...getEmailRecognitionEvents(formData.value.email)
      }
    }),
    extendField(getPasswordField('password'), {
      rules: 'required|min:8|password',
      attributes: {
        providerName: 'password',
        autocomplete: 'new-password'
      }
    }),
    extendField(getPasswordField('confirmedPassword'), {
      rules: 'required|min:8|password|confirmed:password',
      attributes: {
        label: i18n.t('checkout.auth.registration.form.confirm_password.label'),
        providerName: 'confirm_password',
        autocomplete: 'new-password'
      }
    }),
    extendField(getCheckbox('remember_me'), {
      attributes: {
        label: i18n.t('checkout.auth.registration.form.remember_me')
      }
    }),
    ...optins.value.map((optin) =>
      extendField(getCheckbox(optin.id), {
        attributes: {
          label: i18n.t(`checkout.auth.registration.form.subscriptions.${optin.label}`),
          name: optin.id,
          class: 'mt-sm-neg'
        }
      })
    )
  ]);

  return {
    formData,
    formFields,
    isEmailRecognized,
    showEmailRecognitionMessage
  };
};
