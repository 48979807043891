import type { Ref } from '@nuxtjs/composition-api';
// eslint-disable-next-line no-duplicate-imports
import { computed } from '@nuxtjs/composition-api';

import { useAsyncTask } from '../useAsyncTask';
import type { UseAsyncMethodsOptions, UseAsyncMethodsResult } from './models';

export function useAsyncMethods<TMethods extends { [K in keyof TMethods]: (...args: unknown[]) => Promise<unknown> }>({
  composableName,
  methodsFactory,
  maskArgIndices = {},
  // the way to override the default error notification
  errorHandlers = {},
  defaultErrorNotificationConfig = {}
}: UseAsyncMethodsOptions<TMethods>): UseAsyncMethodsResult<TMethods> {
  const methodsWithExecute = {} as { [K in keyof TMethods]: TMethods[K] };
  const loading = {} as { [K in keyof TMethods]: Ref<boolean> };
  const errors = {} as { [K in keyof TMethods]: Ref<Error | null> };

  const actualMethods = methodsFactory(methodsWithExecute);

  for (const methodName in actualMethods) {
    const fn = actualMethods[methodName];
    const methodMaskArgIndices = maskArgIndices[methodName] || [];
    const methodErrorHandler = errorHandlers[methodName];

    const task = useAsyncTask({
      composableName,
      methodName: methodName as string,
      fn,
      maskArgIndices: methodMaskArgIndices,
      onError: methodErrorHandler,
      defaultErrorNotificationConfig
    });

    methodsWithExecute[methodName] = task.execute as TMethods[typeof methodName];
    loading[methodName] = task.loading;
    errors[methodName] = task.error;
  }

  const overallLoading = computed(() => {
    return Object.values(loading).some((l: Ref<boolean>) => l.value);
  });

  return {
    methods: methodsWithExecute,
    loading,
    errors,
    overallLoading
  };
}
