var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-register-form"},[_c('RequiredFieldsNote',{staticClass:"user-register-form__required-fields-note"}),_vm._v(" "),_c('FormGenerator',{attrs:{"fields":_vm.formFields,"is-submit-button-disabled":_vm.loading || _vm.isEmailRecognized || !_vm.showEmailRecognitionMessage,"submit-button-text":_vm.$t('Sign in')},on:{"submit":_vm.onFormSubmit},scopedSlots:_vm._u([(_vm.optins.length)?{key:("before-" + (_vm.optins[0].id)),fn:function(){return [_c('p',{staticClass:"user-register-form__subscriptions-title"},[_vm._v("\n        "+_vm._s(_vm.$t('checkout.auth.registration.form.subscriptions.title'))+"\n      ")])]},proxy:true}:null,{key:"email-error",fn:function(ref){
var errorMessage = ref.errorMessage;
return [(!errorMessage && _vm.showEmailRecognitionMessage)?_c('div',{staticClass:"user-register-form__message",class:("user-register-form__message--" + (!_vm.isEmailRecognized ? 'success' : 'notification')),attrs:{"aria-label":("" + (_vm.$t(
          _vm.isEmailRecognized
            ? 'Email address found'
            : 'This email is new! Create an account and enjoy multiple benefits'
        )))}},[_c('VaimoIcon',{attrs:{"icon":!_vm.isEmailRecognized ? 'check' : 'cross',"size":12}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t(
            _vm.isEmailRecognized
              ? 'Email address found'
              : 'This email is new! Create an account and enjoy multiple benefits'
          )))])],1):_vm._e()]}}],null,true),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }