























import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';

import HeaderLogo from '~/components/HeaderLogo.vue';
import { useScreenSize } from '~/diptyqueTheme/composable';

export default defineComponent({
  name: 'CheckoutHeader',
  components: {
    SfButton,
    HeaderLogo
  },
  setup() {
    const { isDesktop } = useScreenSize();

    return {
      isDesktop
    };
  }
});
