import { useAsyncMethods, useAuth, useCart, useOrder } from '@checkout/composables';
import { CUSTOMER_DATA_TO_GET_ORDER } from '@checkout/constants';
import { PaymentService } from '@checkout/services';
import { ShippingMethodsService } from '@checkout/services';
import { useCheckoutStore } from '@checkout/stores';
import { computed, useContext, useRouter } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';

import { useUiNotification } from '~/composables';
import { useStoreLocale } from '~/composables/useStoreLocale';
import type { AvailablePaymentMethod } from '~/models';

export const usePayment = () => {
  const { cart, clearCart, updateCartPartially } = useCart();
  const { isAuthenticated } = useAuth();
  const checkoutStore = useCheckoutStore();
  const { paymentMethodCode } = storeToRefs(checkoutStore);

  const { isJpStore } = useStoreLocale();

  const { send: sendNotification } = useUiNotification();
  const router = useRouter();

  const { app } = useContext();
  const shippingMethodsService = new ShippingMethodsService(app);

  const paymentMethods = computed<AvailablePaymentMethod[]>(() => cart.value?.available_payment_methods || []);

  const selectedPaymentMethod = computed(() => paymentMethods.value.find(({ code }) => code === paymentMethodCode.value));

  const proceedAdyenPayment = () => {
    const simulateClick = function (elem) {
      const evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      });

      elem.dispatchEvent(evt);
    };

    const selectedMethod = document.querySelector('.adyen-checkout__payment-method--selected');

    if (selectedMethod) {
      const paypalButton = selectedMethod.querySelector('.adyen-checkout__paypal__buttons');
      const appleButton = selectedMethod.querySelector('.adyen-checkout__applepay__button');

      if (paypalButton) {
        simulateClick(paypalButton);
      } else if (appleButton) {
        simulateClick(appleButton);
      } else {
        const selectedMethodPayButton = selectedMethod.querySelector('.adyen-checkout__button--pay');
        simulateClick(selectedMethodPayButton);
      }
    }
  };

  const setCustomerOrderData = () => {
    if (isAuthenticated.value) {
      return;
    }

    sessionStorage.setItem(
      CUSTOMER_DATA_TO_GET_ORDER,
      JSON.stringify({
        customerEmail: cart.value?.email,
        customerLastName: cart.value?.billing_address?.lastname
      })
    );
  };

  const {
    placeOrder,
    errors: { placeOrder: placeOrderError }
  } = useOrder();

  const proceedDefaultPayment = async () => {
    const order = await placeOrder();

    if (placeOrderError.value || !order?.order_number) {
      const message = placeOrderError.value?.message || app.i18n.t('checkout.payment.errors.refused_payment');

      sendNotification({
        id: Symbol('use_make_order_error'),
        message: `${message}`,
        persist: false,
        type: 'danger',
        icon: 'cross'
      });

      return;
    }

    setCustomerOrderData();
    clearCart();

    await router.push(
      app.localeRoute({
        name: 'thank-you',
        query: {
          order: order.order_number
        }
      })
    );
  };

  const paymentService = new PaymentService(app);

  const { methods, errors, loading } = useAsyncMethods({
    composableName: 'usePayment',
    methodsFactory: () => ({
      makePayment: async (triggerGmoPayment?: () => Promise<void>) => {
        if (cart.value?.vw_delivery_date_info?.delivery_date && isJpStore.value) {
          const { isValidJpDeliveryDate } = await shippingMethodsService.getIsValidJpDeliveryDate({
            cart_id: cart.value.id
          });

          if (!isValidJpDeliveryDate?.is_valid_jp_delivery_date) {
            const message = app.i18n.t('Your last selected delivery date in the cart is outdated. Please check it again.') as string;
            sendNotification({
              id: Symbol('set_click_and_collect_shop_error'),
              message: message,
              type: 'danger',
              persist: false,
              title: app.i18n.t('Something went wrong!') as string
            });

            return;
          }
        }
        if (!isJpStore.value) {
          proceedAdyenPayment();
        } else if (paymentMethodCode.value.startsWith('np_gmo_payment_credit_card')) {
          await triggerGmoPayment?.();
        } else {
          await proceedDefaultPayment();
        }
      },

      setPaymentMethod: async (code: string, params: Record<PropertyKey, unknown> = {}) => {
        checkoutStore.setPaymentMethodCode(code);

        const { setPaymentMethodOnCart } = await paymentService.setPaymentMethod({
          cart_id: cart.value.id,
          payment_method: {
            code,
            ...params
          }
        });

        if (setPaymentMethodOnCart.cart) {
          updateCartPartially(setPaymentMethodOnCart.cart);
        }
      },

      getPaymentMethods: async () => {
        const result = await paymentService.getAvailablePaymentMethods({ cartId: cart.value.id });

        if (!result.cart) {
          return [];
        }

        updateCartPartially(result.cart);

        return result.cart?.available_payment_methods || [];
      }
    })
  });

  return {
    ...methods,
    errors,
    loading,
    selectedPaymentMethodCode: paymentMethodCode,
    selectedPaymentMethod,
    paymentMethods,
    setCustomerOrderData
  };
};
