























import { useCheckoutStep } from '@checkout/composables';
import { steps } from '@checkout/constants';
import AccordionContainer from '@checkout-molecules/accordion/accordion-container/AccordionContainer.vue';
import AccordionItem from '@checkout-molecules/accordion/accordion-item/AccordionItem.vue';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CheckoutStepper',
  components: { AccordionItem, AccordionContainer },
  setup() {
    const {
      state: stepsState,
      isLoggedLoginStep,
      activeStep,
      activateNextStepFunc,
      toggleStepFunc
    } = useCheckoutStep();

    return {
      steps,
      stepsState,
      activeStep,
      activateNextStepFunc,
      toggleStepFunc,
      isLoggedLoginStep
    };
  }
});
