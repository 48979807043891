import { useAuth, useCart, usePayment } from '@checkout/composables';
import { ref, useContext, useRouter } from '@nuxtjs/composition-api';

import { useUiNotification } from '~/composables';
import { useGoogleTagManager } from '~/diptyqueTheme/composable';
import { useCart as useOldCart } from '~/modules/core/composables/useCart';

export const useAdyenPayment = () => {
  const { app } = useContext();
  const { send: sendNotification } = useUiNotification();
  const router = useRouter();
  const { setCustomerOrderData } = usePayment();
  const { getAddPaymentInfoDetails } = useGoogleTagManager();
  const { isAuthenticated } = useAuth();
  const { cart, clearCart } = useCart();
  const { setCart } = useOldCart();

  const loading = ref(false);

  const prepareStateData = (data) => {
    const stateData = JSON.parse(JSON.stringify(data));

    // todo: @i.sprut refactor
    if (isAuthenticated.value) {
      const isSavedCart = stateData.paymentMethod?.brand && !('storePaymentMethod' in stateData);

      if (isSavedCart || stateData.storePaymentMethod) {
        stateData.recurringProcessingModel = 'CardOnFile';
        stateData.storePaymentMethod = true;
      }
    }

    return stateData;
  };

  const onAdyenBeforePay = (stateData) => {
    loading.value = true;
    app.$gtm.push(getAddPaymentInfoDetails(cart.value));

    return prepareStateData(stateData);
  };

  const onAdyen3dsLoaded = () => {
    loading.value = false;
  };

  const onAdyenAfterPay = async ({ order }) => {
    setCustomerOrderData();
    app.$vsf.$magento.config.state.removeCartId();
    clearCart();
    setCart(null);

    loading.value = false;

    await router.push(
      app.localeRoute({
        name: 'thank-you',
        query: {
          order: order.id
        }
      })
    );
  };

  const onAdyenErrorCaptured = async ({ error }) => {
    loading.value = false;

    const message = error?.message || app.i18n.t('checkout.payment.errors.refused_payment');

    sendNotification({
      id: Symbol('adyen_dropin_error'),
      message: message,
      persist: false,
      type: 'danger',
      icon: 'cross'
    });
  };

  return {
    loading,
    onAdyenBeforePay,
    onAdyenAfterPay,
    onAdyen3dsLoaded,
    onAdyenErrorCaptured
  };
};
