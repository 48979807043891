import { useMagentoState } from '@checkout/composables';
import { useCartStore, useUserStore } from '@checkout/stores';
import { computed } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';

export const useAuth = () => {
  const userStore = useUserStore();
  const { getCustomerToken } = useMagentoState();
  const { userInfo, isLoggedIn } = storeToRefs(userStore);
  const cartStore = useCartStore();
  const { mergeCartsFlag } = storeToRefs(cartStore);

  const isAuthenticated = computed<boolean>(() => isLoggedIn.value && !!userInfo.value && Boolean(getCustomerToken()) && !mergeCartsFlag.value);

  return {
    isAuthenticated
  };
};
