




























import { useCart } from '@checkout/composables';
import { useCheckoutStore } from '@checkout/stores';
import ReinsuranceList from '@checkout-molecules/reinsurance/ReinsuranseList.vue';
import AuthStep from '@checkout-organisms/auth-step/AuthStep.vue';
import OrderSummary from '@checkout-organisms/order-summary/OrderSummary.vue';
import PaymentStep from '@checkout-organisms/payment/PaymentStep.vue';
import Shipping from '@checkout-organisms/shipping-step/ShippingStep.vue';
import Stepper from '@checkout-organisms/stepper/Stepper.vue';
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  useContext,
  useMeta
} from '@nuxtjs/composition-api';
import NostoCart from 'atoms/NostoCart.vue';
import VaimoButton from 'atoms/VaimoButton.vue';

export default defineComponent({
  name: 'OnePageCheckout',
  components: {
    VaimoButton,
    NostoCart,
    PaymentStep,
    AuthStep,
    Shipping,
    Stepper,
    OrderSummary,
    ReinsuranceList
  },
  layout: 'one-page',
  scrollToTop: true,
  setup() {
    const { cart, loadCart } = useCart();
    const { app, i18n, route, redirect } = useContext();
    const checkoutStore = useCheckoutStore();
    const routeName = computed(() => route.value.name);

    onBeforeMount(async () => {
      await loadCart();

      const isNonCheckoutPage = !['thank-you', 'error-page'].includes(
        routeName.value
      );
      const isEmptyCart = cart.value.items.length === 0;

      if (isNonCheckoutPage && isEmptyCart) {
        redirect(app.localePath('/'));
        return;
      }
    });

    onMounted(() => {
      checkoutStore.setPageLoading(false);
    });

    useMeta({
      title: `${i18n.t('Checkout')} | Diptyque Paris`,
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: `${i18n.t('Checkout')} | Diptyque Paris`
        }
      ]
    });

    // TODO: for testing purposes. remove after test
    const triggerError = () => {
      throw new Error('Something went wrong');
    };

    return {
      cart,
      triggerError
    };
  },
  head: {}
});
