import { useAsyncMethods, useCart } from '@checkout/composables';
import { OrderService } from '@checkout/services';
import { useContext } from '@nuxtjs/composition-api';

export const useOrder = () => {
  const { app } = useContext();
  const { cart } = useCart();

  const orderService = new OrderService(app);

  const {
    methods: { placeOrder },
    errors,
    loading,
    overallLoading
  } = useAsyncMethods({
    composableName: 'useOrder',
    methodsFactory: () => ({
      placeOrder: async () => {
        const result = await orderService.placeOrder({ cart_id: cart.value.id });

        return result.placeOrder?.order;
      }
    })
  });

  return {
    placeOrder,
    errors,
    loading,
    overallLoading
  };
};
