













































































import {
  useAdyenPayment,
  useAuth,
  useGmoPayment,
  usePayment,
  useShippingAddress
} from '@checkout/composables';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useContext
} from '@nuxtjs/composition-api';
import { SfButton, SfLoader } from '@storefront-ui/vue';

import { useStoreLocale } from '~/composables/useStoreLocale';
import termsOfServiceLinks from '~/diptyqueTheme/config/termsOfServiceLinks';

export default defineComponent({
  name: 'PaymentStep',
  components: {
    SfLoader,
    SfButton,
    AdyenPaymentProvider: () =>
      import('~/integrations/adyen/components/AdyenPaymentProvider.vue'),
    NpPaymentProvider: () =>
      import('@checkout-molecules/NpPaymentProvider.vue'),
    GmoPaymentProvider: () =>
      import(
        '~/diptyqueTheme/modules/gmo-payment/components/GmoPaymentProvider.vue'
      )
  },
  setup() {
    const { isAuthenticated } = useAuth();
    const { shippingAddress, billingAddress } = useShippingAddress();
    const { app } = useContext();
    const { isJpStore, isDeStore } = useStoreLocale();
    const areAddressesDifferent = computed(() => {
      return (
        JSON.stringify(shippingAddress.value) !==
        JSON.stringify(billingAddress.value)
      );
    });

    const isJpPaymentProvidersReady = ref(false);
    const gmoPaymentProviderRef = ref(null);

    const {
      getPaymentMethods,
      makePayment,
      loading: {
        getPaymentMethods: getPaymentMethodsLoading,
        makePayment: makePaymentLoading
      }
    } = usePayment();

    onMounted(async () => {
      await getPaymentMethods();
    });

    const termsOfServiceLink = computed<string>(() => {
      return termsOfServiceLinks[app.i18n.locale].terms_link;
    });

    const {
      onAdyenBeforePay,
      onAdyenAfterPay,
      onAdyenErrorCaptured,
      onAdyen3dsLoaded,
      loading: adyenPaymentLoading
    } = useAdyenPayment();

    const {
      onGmoBeforePay,
      onGmoAfterPay,
      onGmoError,
      loading: {
        getPaymentMethods: getGMOPaymentMethodsLoading,
        gmoPayment: gmoPaymentLoading
      }
    } = useGmoPayment();

    const submit = () => {
      makePayment(gmoPaymentProviderRef.value?.triggerGmoPayment);
    };

    return {
      isAuthenticated,
      onAdyenBeforePay,
      onAdyenAfterPay,
      onAdyenErrorCaptured,
      areAddressesDifferent,
      termsOfServiceLink,
      isDeStore,
      submit,
      loading: computed(
        () =>
          getGMOPaymentMethodsLoading.value ||
          getPaymentMethodsLoading.value ||
          adyenPaymentLoading.value ||
          gmoPaymentLoading.value ||
          makePaymentLoading.value
      ),
      isJpStore,
      isJpPaymentProvidersReady,
      gmoPaymentProviderRef,
      onGmoBeforePay,
      onGmoAfterPay,
      onGmoError,
      onAdyen3dsLoaded
    };
  }
});
