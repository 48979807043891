








import UserRegistrationForm from '@checkout-organisms/auth/registration-form/UserRegistrationForm.vue';
import { defineComponent } from '@nuxtjs/composition-api';
import LegalLinks from 'organisms/LegalLinks.vue';
import VaimoSocialLogin from 'organisms/VaimoSocialLogin.vue';

export default defineComponent({
  name: 'RegistrationSection',
  components: { LegalLinks, VaimoSocialLogin, UserRegistrationForm }
});
