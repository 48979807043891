



















import { useUser } from '@checkout/composables';
import RequiredFieldsNote from '@checkout-atoms/required-fields-note/RequiredFieldsNote.vue';
import { useGuestUserForm } from '@checkout-organisms/auth/guest-user-form/composables/useGuestUserForm';
import type { GuestUserForm } from '@checkout-organisms/auth/guest-user-form/models';
import { FormGenerator } from '@form-generator';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'GuestUserForm',
  components: {
    RequiredFieldsNote,
    FormGenerator
  },
  emits: ['cancel', 'submitted'],
  setup(props, { emit }) {
    const { formData, formFields } = useGuestUserForm();

    const {
      setGuestEmail,
      loading: { setGuestEmail: submitLoading },
      errors: { setGuestEmail: setGuestEmailError }
    } = useUser();

    const onSubmit = async (data: GuestUserForm) => {
      await setGuestEmail(data);
      if (!setGuestEmailError.value) {
        emit('submitted');
      }
    };

    const cancel = () => {
      emit('cancel');
    };

    return {
      formData,
      formFields,
      onSubmit,
      submitLoading,
      cancel
    };
  }
});
