













































import type { PropType } from '@nuxtjs/composition-api';
import { defineComponent } from '@nuxtjs/composition-api';

import { useStore } from '~/composables';
import type { Boutique } from '~/models';

export default defineComponent({
  name: 'BoutiqueSelector',
  props: {
    boutiquesList: {
      type: Array as PropType<Boutique[]>,
      required: true
    },
    selectedBoutique: {
      type: Object as PropType<Boutique>,
      default: null
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  emits: ['select', 'toggle'],
  setup() {
    const { isUsCaStore } = useStore();

    const getFormatedDistance = (boutique: Boutique) => {
      if (!boutique || typeof boutique.distance !== 'number') {
        return '0 km';
      }

      const distanceUnit = isUsCaStore.value ? 'mi' : 'km';
      const DIVIDER = 1000;
      return `${(boutique.distance / DIVIDER).toFixed(1)} ${distanceUnit}`;
    };

    return {
      getFormatedDistance
    };
  }
});
