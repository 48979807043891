import { useAsyncMethods, useCountries } from '@checkout/composables';
import type { UseUserAddressMethods, UseUserAddressResult } from '@checkout/composables/useUserAddress/models';
import { UserService } from '@checkout/services';
import { useCheckoutStore } from '@checkout/stores/checkout.store';
import { useUserStore } from '@checkout/stores/user/user.store';
import { computed, useContext } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';

import type { Address, AddressInput, AddressType } from '~/models';
import { AddressTypeEnum } from '~/models';

export const useUserAddress = (): UseUserAddressResult => {
  const { app } = useContext();
  const userService = new UserService(app);

  const userStore = useUserStore();
  const { billingAddresses, shippingAddresses } = storeToRefs(userStore);

  const { allowedCountries } = useCountries();

  const allowedShippingAddresses = computed(() => {
    const allowedCountryIds = new Set(allowedCountries.value.map((country) => country.id));
    return shippingAddresses.value.filter((address) => allowedCountryIds.has(address.country_code));
  });

  const checkoutStore = useCheckoutStore();

  const {
    methods: methodsWithExecute,
    loading,
    errors,
    overallLoading
  } = useAsyncMethods<UseUserAddressMethods>({
    composableName: 'useUserAddress',
    maskArgIndices: {
      addAddress: [0],
      updateAddress: [1]
    },
    methodsFactory: (methodsWithExecute) => ({
      getAddresses: async (): Promise<Address[]> => {
        const {
          customer: { addresses }
        } = await userService.getAddresses();

        userStore.setUserAddresses(addresses);

        return addresses;
      },

      addAddress: async (address: AddressInput): Promise<Address | null> => {
        const createAddressResponse = await userService.createAddress(address);
        await methodsWithExecute.getAddresses();

        const newAddressId = createAddressResponse.createCustomerAddress?.id;
        if (!newAddressId) return null;

        return (
          shippingAddresses.value.find((addr) => addr.id === newAddressId) || billingAddresses.value.find((addr) => addr.id === newAddressId) || null
        );
      },

      updateAddress: async (id: number, address: AddressInput): Promise<void> => {
        await userService.updateAddress(id, address);
        await methodsWithExecute.getAddresses();
      },

      deleteAddress: async (id: number): Promise<void> => {
        await userService.deleteAddress(id);
        await methodsWithExecute.getAddresses();
      },

      setDefaultAddress: async (id: number, type: AddressType): Promise<void> => {
        if (type === AddressTypeEnum.BILLING) {
          checkoutStore.setBillingAddressId(id);
        } else if (type === AddressTypeEnum.SHIPPING) {
          checkoutStore.setShippingAddressId(id);
        }
      }
    })
  });

  const defaultShippingAddress = computed(() => {
    return shippingAddresses.value.find(({ id }) => id === checkoutStore.shippingAddressId);
  });

  const defaultBillingAddress = computed(() => {
    return billingAddresses.value.find(({ id }) => id === checkoutStore.billingAddressId);
  });

  return {
    ...methodsWithExecute,
    loading,
    errors,
    overallLoading,

    defaultShippingAddress,
    defaultBillingAddress,

    billingAddresses,
    shippingAddresses,
    allowedShippingAddresses
  };
};
