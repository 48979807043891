






































































import { useCountries } from '@checkout/composables';
import { useCartStore, useCheckoutStore } from '@checkout/stores';
import { useShippingStep } from '@checkout-organisms/shipping-step/composables/useShippingStep';
import {
  computed,
  defineComponent,
  onMounted,
  useContext
} from '@nuxtjs/composition-api';
import VaimoButton from 'atoms/VaimoButton.vue';
import VaimoCheckbox from 'molecules/VaimoCheckbox.vue';
import { storeToRefs } from 'pinia';

import { useGoogleTagManager } from '~/diptyqueTheme/composable';
import { AddressTypeEnum } from '~/models';
import type { Cart } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'ShippingStep',
  components: {
    AddressSection: () =>
      import('@checkout-organisms/shipping-step/_internal').then(
        (m) => m.AddressSection
      ),
    ShippingProvider: () =>
      import('@checkout-organisms/shipping-provider/ShippingProvider.vue'),
    ShippingComplete: () =>
      import('@checkout-organisms/shipping-step/_internal').then(
        (m) => m.ShippingComplete
      ),
    VaimoCheckbox,
    VaimoButton
  },
  props: {
    isActive: {
      type: Boolean
    }
  },
  emits: ['next'],
  setup(_, { emit }) {
    const { app } = useContext();
    const { getAddShippingInfoDetails, getAddDeliveryAddressDetails } =
      useGoogleTagManager();
    const { getAllowedCountries } = useCountries();
    const checkoutStore = useCheckoutStore();
    const cartStore = useCartStore();
    const { cart } = storeToRefs(cartStore);
    const { isShippingAddressFormVisible, isAddressFormsVisible } =
      storeToRefs(checkoutStore);

    const {
      isBillingSectionValid,
      shippingAddressForm,
      billingAddressForm,
      isBillingAddressSameAsShipping,
      setAddressSectionValidationStatus,
      toggleIsBillingAddressSameAsShipping,
      updateAddressForm,
      saveSelectedShippingAddressAsBilling,
      submitAddresses,
      areDataCorrect,
      showValidationMessage,
      isLoading,
      isShippingMethodsVisible
    } = useShippingStep();

    const handleProceedToPayment = async () => {
      await saveSelectedShippingAddressAsBilling();
      app.$gtm.push(getAddDeliveryAddressDetails());
      app.$gtm.push(getAddShippingInfoDetails(cart.value as unknown as Cart));
      emit('next');
    };

    const isSaveButtonVisible = computed<boolean>(() => {
      return isAddressFormsVisible.value || isShippingAddressFormVisible.value;
    });

    onMounted(async () => {
      isBillingSectionValid.value = isBillingAddressSameAsShipping.value;

      await getAllowedCountries();
    });

    return {
      isLoading,
      areDataCorrect,
      shippingAddressForm,
      billingAddressForm,
      isBillingAddressSameAsShipping,
      submitAddresses,
      updateAddressForm,
      toggleIsBillingAddressSameAsShipping,
      setAddressSectionValidationStatus,
      showValidationMessage,
      isSaveButtonVisible,
      AddressTypeEnum,
      isShippingMethodsVisible,
      handleProceedToPayment
    };
  }
});
