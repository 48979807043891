import { useCart, useCountries } from '@checkout/composables';
import { useAsyncMethods } from '@checkout/composables/core';
import { ShippingAddressesService } from '@checkout/services';
import { useCartStore, useCountriesStore } from '@checkout/stores';
import type { AddressForm } from '@checkout-organisms/shipping-step/models';
import { computed, useContext } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';

import { Logger } from '~/helpers/logger';
import type {
  AvailableRegion,
  CartShippingAddress,
  CartShippingAddressInput,
  CountryCodeEnum,
  SetBillingAddressOnCartInput,
  SetShippingAddressesOnCartInput
} from '~/models';

export const useShippingAddress = () => {
  const { cart, updateCartPartially } = useCart();
  const cartStore = useCartStore();
  const { hasOnlyBooxiItemsInCart } = storeToRefs(cartStore);
  const countriesStore = useCountriesStore();
  const { getCountryById } = storeToRefs(countriesStore);

  const { app } = useContext();
  const shippingAddressesService = new ShippingAddressesService(app);
  const { allowedCountries, allCountries } = useCountries();

  const shippingAddress = computed(() => cart.value?.shipping_addresses?.[0]);
  const billingAddress = computed(() => cart.value?.billing_address?.[0]);

  const availableShippingAddresses = computed(() => cart.value?.shipping_addresses);

  const getRegion = (countryCode: CountryCodeEnum, regionCode: string, searchInAvailableCountries?: boolean): AvailableRegion | undefined => {
    const countries = searchInAvailableCountries ? allowedCountries.value : allCountries.value;
    return countries.find(({ abbreviation }) => abbreviation === countryCode)?.availableRegions?.find(({ code }) => code === regionCode);
  };

  const generateSetShippingAddressesOnCartInput = (address: AddressForm): SetShippingAddressesOnCartInput => {
    const region = address.country_code && address.region_code ? getRegion(address.country_code, address.region_code, true) : null;

    return {
      cart_id: cart.value?.id,
      shipping_addresses: [
        {
          address: {
            city: address.city,
            firstname: address.firstname,
            lastname: address.lastname,
            postcode: address.postcode,
            street: [address.street, address.apartment].filter(Boolean),
            telephone: address.telephone,
            country_code: (address.country_code as CountryCodeEnum) || null,
            region_id: region?.id || null,
            firstnamekana: address.firstnamekana,
            lastnamekana: address.lastnamekana
          }
        }
      ]
    };
  };

  const generateSetBillingAddressOnCartInput = (address: AddressForm, isSameAsShipping: boolean): SetBillingAddressOnCartInput => {
    const region = address.country_code && address.region_code ? getRegion(address.country_code, address.region_code, true) : null;

    return {
      cart_id: cart.value?.id,
      billing_address: {
        same_as_shipping: isSameAsShipping,
        address: {
          city: address.city,
          firstname: address.firstname,
          lastname: address.lastname,
          postcode: address.postcode,
          street: [address.street, address.apartment].filter(Boolean),
          telephone: address.telephone,
          country_code: (address.country_code as CountryCodeEnum) || null,
          region_id: region?.id || null,
          firstnamekana: address.firstnamekana,
          lastnamekana: address.lastnamekana
        }
      }
    };
  };

  const generateCartAddressFromCartShippingAddressInput = (addressInput: CartShippingAddressInput): CartShippingAddress => {
    try {
      // Check if input is using customer_address_id format
      if ('customer_address_id' in addressInput) {
        throw new Error('Cannot convert from customer_address_id without customer address data');
      }

      return {
        firstname: addressInput.address.firstname,
        lastname: addressInput.address.lastname,
        firstnamekana: addressInput.address.firstnamekana || null,
        lastnamekana: addressInput.address.lastnamekana || null,
        street: addressInput.address.street || [],
        city: addressInput.address.city,
        postcode: addressInput.address.postcode || null,
        telephone: addressInput.address.telephone || null,
        region: {
          region_id: addressInput.address.region_id || null
        },
        country: {
          label: getCountryById.value(addressInput.address.country_code)?.label || addressInput.address.country_code,
          code: addressInput.address.country_code
        }
      };
    } catch (error) {
      Logger.error('Error converting cart shipping address input: ', error);
    }
  };

  const setShippingMethodForVirtualProducts = (address: CartShippingAddressInput) => {
    const shippingAddress = generateCartAddressFromCartShippingAddressInput(address);

    cartStore.setShippingMethodOnVirtualProductsInCart(shippingAddress);
  };

  const {
    methods: methodsWithExecute,
    loading,
    errors,
    overallLoading
  } = useAsyncMethods({
    composableName: 'useShippingAddress',
    methodsFactory: () => ({
      saveSelectedShippingAddress: async (address: CartShippingAddressInput) => {
        if (!hasOnlyBooxiItemsInCart.value) {
          const cartResponse = await shippingAddressesService.setShippingAddresses({
            cart_id: cart.value?.id,
            shipping_addresses: [address]
          });

          updateCartPartially(cartResponse.setShippingAddressesOnCart.cart);
        } else {
          setShippingMethodForVirtualProducts(address);
        }
      },

      saveSelectedBillingAddress: async (address: CartShippingAddressInput) => {
        const cartResponse = await shippingAddressesService.setBillingAddressOnCart({
          cart_id: cart.value?.id,
          billing_address: address
        });

        updateCartPartially(cartResponse.setBillingAddressOnCart.cart);
      },

      saveShippingAddressForm: async (shippingAddress: AddressForm) => {
        const address = generateSetShippingAddressesOnCartInput(shippingAddress);

        if (!hasOnlyBooxiItemsInCart.value) {
          const cartResponse = await shippingAddressesService.setShippingAddresses(address);

          updateCartPartially(cartResponse.setShippingAddressesOnCart.cart);
        } else {
          setShippingMethodForVirtualProducts(address.shipping_addresses[0]);
        }
      },

      saveBillingAddressForm: async (shippingAddress: AddressForm, isSameAsShipping: boolean) => {
        const address = generateSetBillingAddressOnCartInput(shippingAddress, isSameAsShipping);
        const cartResponse = await shippingAddressesService.setBillingAddressOnCart(address);

        updateCartPartially(cartResponse.setBillingAddressOnCart.cart);
      }
    })
  });

  return {
    ...methodsWithExecute,
    loading,
    errors,
    overallLoading,

    getRegion,
    shippingAddress,
    billingAddress,
    availableShippingAddresses
  };
};
