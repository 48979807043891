






















import { useAuth } from '@checkout/composables';
import { AddressOption } from '@checkout-organisms/shipping-step/_internal';
import type { PropType } from '@nuxtjs/composition-api';
import { computed, defineComponent, ref, toRef } from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';

import type { Address, AddressType } from '~/models';

export default defineComponent({
  name: 'AddressesList',
  components: {
    AddressOption,
    LazyHydrate,
    EditAddressModal: () =>
      import('@checkout-organisms/shipping-step/_internal').then(
        (m) => m.EditAddressModal
      )
  },
  props: {
    list: {
      type: Array as PropType<Address[]>,
      default: () => [] as Address[]
    },
    addressType: {
      type: String as PropType<AddressType>,
      required: true
    },
    activeId: {
      type: [Number, null],
      default: null
    }
  },
  emits: ['set-active'],
  setup(props, { emit }) {
    const { isAuthenticated } = useAuth();
    const addressesList = toRef(props, 'list');
    const showEditModal = ref<boolean>(false);
    const editableAddress = ref<Address | null>(null);

    const sortedAddresses = computed<Address[]>(() =>
      addressesList.value.sort(
        (a, b) =>
          (b.default_shipping || b.default_billing ? 1 : 0) -
          (a.default_shipping || a.default_billing ? 1 : 0)
      )
    );

    const setActive = (address: Address) => {
      if (props.activeId !== address.id && isAuthenticated.value)
        emit('set-active', address);
    };

    const onEditAddress = (address: Address) => {
      editableAddress.value = address;
      showEditModal.value = true;
    };

    const onCloseEdit = () => {
      editableAddress.value = null;
      showEditModal.value = false;
    };

    const handleEditSubmit = (address: Address) => {
      setActive(address);
    };

    return {
      isAuthenticated,
      showEditModal,
      editableAddress,
      sortedAddresses,
      onCloseEdit,
      setActive,
      onEditAddress,
      handleEditSubmit
    };
  }
});
