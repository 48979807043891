















































import { useUser } from '@checkout/composables';
import RequiredFieldsNote from '@checkout-atoms/required-fields-note/RequiredFieldsNote.vue';
import { FormGenerator } from '@form-generator';
import { defineComponent } from '@nuxtjs/composition-api';

import { useOptins } from '~/composables';

import { useRegistrationForm } from './composables';

export default defineComponent({
  name: 'UserRegistrationForm',
  components: { RequiredFieldsNote, FormGenerator },
  setup() {
    const { optins } = useOptins();

    const {
      formFields,
      formData,
      isEmailRecognized,
      showEmailRecognitionMessage
    } = useRegistrationForm();

    const {
      register,
      loading: { register: loading }
    } = useUser();

    const onFormSubmit = async () => {
      await register(formData.value);
    };

    return {
      formData,
      formFields,
      onFormSubmit,
      loading,
      optins,
      isEmailRecognized,
      showEmailRecognitionMessage
    };
  }
});
