











import { useCart } from '@checkout/composables';
import { useCheckoutStore } from '@checkout/stores';
import Header from '@checkout-organisms/header/Header.vue';
import {
  defineComponent,
  onMounted,
  useContext
} from '@nuxtjs/composition-api';
import { onSSR } from '@vue-storefront/core';
import { storeToRefs } from 'pinia';

import ErrorBoundary from '~/components/ErrorBoundary/ErrorBoundary.vue';
import { useConfig } from '~/composables';
import VaimoLoader from '~/diptyqueTheme/components/atoms/VaimoLoader.vue';
import { useGoogleTagManager } from '~/diptyqueTheme/composable';

export default defineComponent({
  name: 'CheckoutLayout',
  components: {
    ErrorBoundary,
    Notification: () => import('~/diptyqueTheme/components/Notification.vue'),
    VaimoLoader,
    Header
  },
  setup() {
    const {
      app: { $gtm }
    } = useContext();
    const { load: loadStoresConfig, config: configStore } = useConfig();
    const { cart } = useCart();
    const { getBeginCheckoutDetails } = useGoogleTagManager();

    onSSR(async () => {
      const storeConfig = configStore.value;

      if (!storeConfig || Object.keys(storeConfig).length === 0) {
        await loadStoresConfig();
      }
    });

    onMounted(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore cart value has all necessary data for getBeginCheckoutDetails
      $gtm.push(getBeginCheckoutDetails(cart.value));
    });

    const checkoutStore = useCheckoutStore();
    const { isPageLoading } = storeToRefs(checkoutStore);

    return {
      isPageLoading
    };
  }
});
