var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"accordion-item border border-secondary",class:{ 'accordion-item--inactive': _vm.inactive },attrs:{"id":_vm.anchorId,"role":"presentation"}},[_c('button',{staticClass:"accordion-item__header",class:{
      'text-grey_58': _vm.disabled,
      'cursor-default': _vm.inactive || _vm.expanded
    },attrs:{"id":_vm.headerId,"aria-expanded":_vm.expanded,"aria-controls":_vm.panelId,"disabled":_vm.disabled},on:{"click":_vm.onHeaderClick}},[_c('span',{staticClass:"accordion-item__header-content"},[_c('span',{staticClass:"accordion-item__header-index",class:{ 'bg-black text-white border-primary': !_vm.disabled }},[_vm._v(_vm._s(_vm.id))]),_vm._v(" "),_c('span',{staticClass:"accordion-item__header-title"},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")])])]),_vm._v(" "),_c('div',{staticClass:"accordion-item__panel",class:{
      'accordion-item__panel--open': _vm.expanded || _vm.static,
      'accordion-item__panel--static': _vm.static && !_vm.expanded,
      'accordion-item__panel--active': !_vm.static && _vm.expanded
    },attrs:{"id":_vm.panelId,"role":"region","aria-labelledby":_vm.headerId}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expanded || _vm.static),expression:"expanded || static"}],staticClass:"accordion-item__panel-content"},[_vm._t("default")],2)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }