import { computed, useRoute, useRouter } from '@nuxtjs/composition-api';

export const useAnchor = () => {
  const router = useRouter();
  const route = useRoute();

  const setAnchor = (name: string) => {
    if (isAnchorActive(name)) return;

    router.replace({ ...route.value, hash: `#${name}` }).catch(() => {});
  };

  const removeAnchor = () => {
    router.replace({ ...route.value, hash: '' });
  };

  const isAnchorActive = (name: string) => {
    return route.value.hash === `#${name}`;
  };

  const activeAnchor = computed(() => route.value.hash.slice(1));

  return {
    setAnchor,
    removeAnchor,
    isAnchorActive,
    activeAnchor
  };
};
