import type { AddressForm } from '@checkout-organisms/shipping-step/models';

import type { CountryCodeEnum } from '~/models';

export const getEmptyUserAddressForm = (withKanaNames?: boolean, defaultCountry?: CountryCodeEnum): AddressForm => {
  return {
    firstname: '',
    lastname: '',
    telephone: '',
    street: '',
    apartment: '',
    postcode: '',
    city: '',
    country_code: defaultCountry || '',
    region_code: '',
    default_billing: false,
    default_shipping: false,
    ...(withKanaNames
      ? {
          firstnamekana: '',
          lastnamekana: ''
        }
      : {})
  };
};
