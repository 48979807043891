import { useAuth, useCountries } from '@checkout/composables';
import { getEmptyUserAddressForm } from '@checkout/helpers/get-empty-user-address-form.helper';
import type { AddressForm } from '@checkout-organisms/shipping-step/models';
import type { ExtraEventParams, Field } from '@form-generator';
import { FieldType, useFields } from '@form-generator';
import { computed, onMounted, ref, useContext } from '@nuxtjs/composition-api';
import type { ParsedAddress } from 'molecules/GoogleAutocomplete/models';

import { useConfig, useStore } from '~/composables';
import { usePostcode } from '~/diptyqueTheme/composable/usePostcode';
import { useRules } from '~/diptyqueTheme/composable/useRules';
import useZip2Address from '~/diptyqueTheme/composable/useZip2Address';
import { Logger } from '~/helpers/logger';
import { orderArrayBySequenceList } from '~/helpers/order-array-by-sequence-list.helper';
import type { AvailableRegion, TransformedCountry } from '~/models';
import { AddressTypeEnum, CountryCodeEnum } from '~/models';

export function useAddressForm(props) {
  const { getTextField, getCheckbox, extendField } = useFields();
  const { app } = useContext();
  const { config } = useConfig();
  const { getRules } = useRules();
  const { isJpStore, isUsCaStore, isZhHkStore, getNameKanaRules } = useStore();
  const { searchZipCode, zip2AddressData } = useZip2Address();
  const { getDefaultCountryPostCode, isHkMoCode } = usePostcode();
  const nameKanaRules = getNameKanaRules();
  const { isAuthenticated } = useAuth();
  const { getCountries, getAllowedCountries, defaultCountryCode, allowedCountries } = useCountries();

  const countries = ref<TransformedCountry[]>([]);
  const isTelephoneFieldValid = ref(true);

  const formData = ref<AddressForm>(
    props.value ? { ...props.value } : getEmptyUserAddressForm(isJpStore.value, defaultCountryCode.value as CountryCodeEnum)
  );

  const regionsList = computed<AvailableRegion[]>(() => {
    return countries.value?.find(({ abbreviation }) => abbreviation === formData.value.country_code)?.availableRegions ?? [];
  });

  const isRegionFieldVisible = computed(() =>
    [CountryCodeEnum.Ca, CountryCodeEnum.Us, CountryCodeEnum.Jp].includes(formData.value.country_code as CountryCodeEnum)
  );

  const isPostcodeFieldVisible = computed(() => ![CountryCodeEnum.Hk, CountryCodeEnum.Mo].includes(formData.value.country_code as CountryCodeEnum));
  const isGoogleSuggestionsActive = computed(() => config.value.google_suggestions_active);

  const isCountryAllowed = (countryCode: string): boolean => {
    const matchedCountry = countries.value.find((country) => country.abbreviation.includes(countryCode));

    return !!matchedCountry;
  };

  const fields = computed<Field[]>(() => {
    const baseFields = [
      extendField(getTextField('firstname'), {
        id: 'firstname',
        rules: getRules({
          en_hk: 'required|chars|doubleQuotes',
          zh_hk: 'required|chars|doubleQuotes',
          ja_jp: 'chars|required',
          default: 'required|name|min:2'
        }),
        wrapperClass: 'col-span-1 field',
        attributes: {
          name: 'firstname',
          autocomplete: 'given-name',
          label: app.i18n.t('checkout.shipping.form.fields.firstname'),
          placeholder: app.i18n.t('checkout.shipping.form.fields.firstname')
        }
      }),
      extendField(getTextField('lastname'), {
        id: 'lastname',
        rules: getRules({
          en_hk: 'required|chars|doubleQuotes',
          zh_hk: 'required|chars|doubleQuotes',
          ja_jp: 'chars|required',
          default: 'required|name|min:2'
        }),
        wrapperClass: 'col-span-1 field',
        attributes: {
          name: 'lastname',
          autocomplete: 'family-name',
          label: app.i18n.t('checkout.shipping.form.fields.lastname'),
          placeholder: app.i18n.t('checkout.shipping.form.fields.lastname')
        }
      })
    ];

    const jpKanaFields = isJpStore.value
      ? [
          extendField(getTextField('firstnamekana'), {
            rules: nameKanaRules,
            wrapperClass: 'desktop:col-span-1 field',
            attributes: {
              label: app.i18n.t('checkout.shipping.form.fields.first_name_kana'),
              placeholder: app.i18n.t('checkout.shipping.form.fields.first_name_kana'),
              autocomplete: 'given-name'
            }
          }),
          extendField(getTextField('lastnamekana'), {
            rules: nameKanaRules,
            wrapperClass: 'desktop:col-span-1 field',
            attributes: {
              label: app.i18n.t('checkout.shipping.form.fields.last_name_kana'),
              placeholder: app.i18n.t('checkout.shipping.form.fields.last_name_kana'),
              autocomplete: 'family-name'
            }
          })
        ]
      : [];

    const telephoneField = [
      {
        id: 'telephone',
        type: FieldType.PHONE,
        wrapperClass: isJpStore.value ? 'desktop:col-span-1 field jp-telephone' : 'desktop:col-span-1 field',
        attributes: {
          name: 'telephone',
          autocomplete: 'tel',
          label: app.i18n.t('checkout.shipping.form.fields.telephone'),
          placeholder: app.i18n.t('checkout.shipping.form.fields.telephone')
        },
        events: {
          isPhoneValid: (value: boolean) => {
            isTelephoneFieldValid.value = value;
          }
        }
      }
    ];

    const streetFields = [
      isGoogleSuggestionsActive.value
        ? {
            id: 'street',
            type: FieldType.GOOGLE_AUTOCOMPLETE,
            rules: getRules({
              en_hk: 'required|chars|doubleQuotes',
              zh_hk: 'required|chars|doubleQuotes',
              default: 'required'
            }),
            wrapperClass: 'desktop:col-span-1 field',
            attributes: {
              name: 'street',
              label: app.i18n.t('checkout.shipping.form.fields.street'),
              placeholder: app.i18n.t('checkout.shipping.form.fields.street'),
              autocomplete: 'address-line1',
              restrictedCountries: props.addressType === AddressTypeEnum.SHIPPING && allowedCountries.value.map((country) => country.abbreviation)
            },
            events: {
              select: (address: Partial<ParsedAddress>) => {
                formData.value.street = `${address.streetNumber || ''} ${address.streetName || ''}`.trim();
                formData.value.country_code = isCountryAllowed(address.country)
                  ? (address.country as CountryCodeEnum)
                  : (defaultCountryCode.value as CountryCodeEnum);
                formData.value.city = address.city || '';
                formData.value.postcode = address.postalCode || '';
                if (isRegionFieldVisible.value) {
                  formData.value.region_code = address.state || '';
                }
              }
            }
          }
        : extendField(getTextField('street'), {
            rules: 'chars|required',
            wrapperClass: 'desktop:col-span-1 field',
            attributes: {
              name: 'street',
              label: app.i18n.t('checkout.shipping.form.fields.street'),
              placeholder: app.i18n.t('checkout.shipping.form.fields.street'),
              autocomplete: 'address-line1'
            }
          }),
      extendField(getTextField('apartment'), {
        rules: getRules({
          en_hk: 'chars|doubleQuotes',
          zh_hk: 'chars|doubleQuotes',
          ja_jp: 'chars',
          default: 'alpha'
        }),
        wrapperClass: 'desktop:col-span-1 field',
        attributes: {
          name: 'apartment',
          label: app.i18n.t('checkout.shipping.form.fields.apartment'),
          placeholder: app.i18n.t('checkout.shipping.form.fields.apartment_placeholder'),
          autocomplete: 'address-line2'
        }
      })
    ];

    const postcodeField = isPostcodeFieldVisible.value
      ? [
          extendField(getTextField('postcode'), {
            rules: getRules({
              ja_jp: 'chars|required|postcode-jp',
              default: 'required|alpha|min:2'
            }),
            wrapperClass: 'desktop:col-span-1 field',
            attributes: {
              name: 'postcode',
              label: app.i18n.t('checkout.shipping.form.fields.postcode'),
              placeholder: app.i18n.t('checkout.shipping.form.fields.postcode'),
              autocomplete: 'postal-code'
            },
            options: {
              eventsExtraParams: {
                fieldValidationData: true
              }
            },
            events: {
              ...(isJpStore.value
                ? {
                    input: async (value: string, extraParams: ExtraEventParams) => {
                      if (extraParams?.fieldValidationData?.valid) {
                        await searchZipCode(value);
                        formData.value.city = zip2AddressData.value.city;

                        const region = regionsList.value.find((region) => region.name === zip2AddressData.value.region);
                        if (region) {
                          formData.value.region_code = region.code;
                        }
                      }
                    }
                  }
                : {})
            }
          })
        ]
      : [];

    const cityField = [
      extendField(getTextField('city'), {
        rules: getRules({
          en_hk: 'required|chars|doubleQuotes',
          zh_hk: 'required|chars|doubleQuotes',
          ja_jp: 'chars|required|min:2|max:30',
          default: 'required|alpha|min:2|max:30'
        }),
        wrapperClass: 'desktop:col-span-1 field',
        attributes: {
          name: 'city',
          label: app.i18n.t('checkout.shipping.form.fields.city'),
          placeholder: app.i18n.t('checkout.shipping.form.fields.city'),
          autocomplete: 'address-level2'
        }
      })
    ];

    const regionField = isRegionFieldVisible.value
      ? [
          {
            id: 'region_code',
            type: FieldType.SELECT,
            rules: 'required',
            wrapperClass: 'desktop:col-span-1 field',
            attributes: {
              name: 'region_code',
              label: app.i18n.t('checkout.shipping.form.fields.region'),
              placeholder: app.i18n.t('checkout.shipping.form.fields.region'),
              autocomplete: 'address-level1',
              options: regionsList.value.map(({ code, name, id }) => ({
                key: id,
                value: code,
                label: name
              }))
            }
          }
        ]
      : [];

    const countryField = !isJpStore.value
      ? [
          {
            id: 'country_code',
            type: FieldType.SELECT,
            rules: 'required|min:2',
            wrapperClass: !(isZhHkStore.value || isUsCaStore.value) ? 'desktop:col-span-1 field' : 'field',
            attributes: {
              name: 'country_code',
              label: app.i18n.t('checkout.shipping.form.fields.country'),
              placeholder: app.i18n.t('checkout.shipping.form.fields.country'),
              autocomplete: 'country',
              options: countries.value?.map(({ abbreviation, label }) => ({
                value: abbreviation,
                label
              }))
            },
            events: {
              input: (value: CountryCodeEnum) => {
                formData.value.region_code = '';

                if (isHkMoCode(value)) {
                  formData.value.postcode = getDefaultCountryPostCode(value);
                }
              }
            }
          }
        ]
      : [];

    const isDefaultAddress =
      isAuthenticated.value && props.hasDefaultAddressCheckbox
        ? isBillingAddressType.value
          ? [
              extendField(getCheckbox('default_billing'), {
                wrapperClass: 'default-address',
                attributes: {
                  label: app.i18n.t('checkout.shipping.form.fields.set_as_default.billing'),
                  autocomplete: 'off'
                }
              })
            ]
          : [
              extendField(getCheckbox('default_shipping'), {
                wrapperClass: 'default-address',
                attributes: {
                  label: app.i18n.t('checkout.shipping.form.fields.set_as_default.shipping'),
                  autocomplete: 'off'
                }
              })
            ]
        : [];

    const addressFields = [...streetFields, ...postcodeField, ...cityField, ...regionField, ...countryField];

    const defaultOrderedFields = [...baseFields, ...jpKanaFields, ...addressFields, ...telephoneField, ...isDefaultAddress];

    if (!props.fieldsSequence.length) {
      return defaultOrderedFields;
    }

    return orderArrayBySequenceList(defaultOrderedFields, props.fieldsSequence, ({ id }) => id);
  });

  const isBillingAddressType = computed(() => props.addressType === AddressTypeEnum.BILLING);

  onMounted(async () => {
    try {
      countries.value = isBillingAddressType.value ? await getCountries() : await getAllowedCountries();
    } catch (error) {
      Logger.error('[ERROR] Failed to fetch countries:', error);
      countries.value = [];
    }

    if (!formData.value.country_code) {
      formData.value.country_code = defaultCountryCode.value as CountryCodeEnum;
    }
  });

  return {
    formData,
    isRegionFieldVisible,
    isPostcodeFieldVisible,
    fields,
    regionsList,
    isTelephoneFieldValid,
    defaultCountryCode
  };
}
