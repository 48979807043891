import { useEmailRecognitionEvents } from '@checkout/composables';
import type { UserLoginForm } from '@checkout-organisms/auth/login-form/models';
import type { Field } from '@form-generator';
import { useFields } from '@form-generator';
import { computed, ref, useContext } from '@nuxtjs/composition-api';

export const useLoginForm = () => {
  const { i18n } = useContext();

  const formData = ref<UserLoginForm>({
    email: '',
    password: '',
    remember_me: true
  });

  const { extendField, getEmailField, getPasswordField, getCheckbox } = useFields();

  const { isEmailRecognized, showEmailRecognitionMessage, getEmailRecognitionEvents, eventsExtraParams } = useEmailRecognitionEvents();

  const formFields = computed<Field[]>(() => [
    extendField(getEmailField('email'), {
      attributes: {
        class: 'input--with-label',
        autocomplete: 'username'
      },
      options: {
        eventsExtraParams
      },
      events: {
        ...getEmailRecognitionEvents(formData.value.email)
      }
    }),
    extendField(getPasswordField('password'), {
      attributes: {
        autocomplete: 'current-password'
      }
    }),
    extendField(getCheckbox('remember_me'), {
      attributes: {
        label: i18n.t('Remember me')
      }
    })
  ]);

  return {
    isEmailRecognized,
    formData,
    formFields,
    showEmailRecognitionMessage
  };
};
