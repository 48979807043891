import { useAsyncMethods, useCart } from '@checkout/composables';
import { ShippingMethodsService } from '@checkout/services';
import { useCartStore, useCheckoutStore } from '@checkout/stores';
import type { ShippingMethodCategory } from '@checkout-organisms/shipping-provider/models/shipping-method-category';
import { computed, ref, useContext } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';

import { useConfig, useStore } from '~/composables';
import { useNarVar } from '~/diptyqueTheme/composable';
import { deliveryDateHelper } from '~/helpers/deliveryDateHelper';
import { Logger } from '~/helpers/logger';
import type { AvailableShippingMethod, DeliveryDateInfo } from '~/models';

export const useShippingMethod = () => {
  const { cart, updateCartPartially } = useCart();
  const checkoutStore = useCheckoutStore();
  const { selectedShippingMethodCode } = storeToRefs(checkoutStore);
  const { isJpStore, getJPDateFormat } = useStore();
  const cartStore = useCartStore();
  const { hasOnlyBooxiItemsInCart } = storeToRefs(cartStore);

  const { getEstimatedDeliveryDates, estimationDeliveryDates, estimationDeliveryMessages, formatDate } = useNarVar();
  const { isInvalidDeliveryDate } = deliveryDateHelper();

  const { app } = useContext();
  const shippingMethodsService = new ShippingMethodsService(app);

  const { config } = useConfig();
  const shipping_categories_general_enabled = config.value?.shipping_categories_general_enabled || false;
  const shipping_categories_additional_name = config.value?.shipping_categories_additional_name || 'Additional';
  const shipping_categories_additional_position = config.value?.shipping_categories_additional_position || '20';
  const shipping_categories_additional_methods = config.value?.shipping_categories_additional_methods || [];
  const shipping_categories_additional_icon = config.value?.shipping_categories_additional_icon || '';
  const shipping_categories_standard_name = config.value?.shipping_categories_standard_name || 'Standard';
  const shipping_categories_standard_position = config.value?.shipping_categories_standard_position || '10';
  const shipping_categories_standard_icon = config.value?.shipping_categories_standard_icon || '';

  const jpDateFormat = computed(() => getJPDateFormat() || 'YYYY-MM-DD');
  const jpDeliveryFromDate = ref('');
  const jpDeliveryToDate = ref('');
  const jpDeliveryTimeList = ref([]);
  const isCartSaving = ref(false);

  const {
    methods: methodsWithExecute,
    loading,
    errors,
    overallLoading
  } = useAsyncMethods({
    composableName: 'useShippingMethod',
    methodsFactory: () => ({
      saveSelectedShippingMethod: async (shippingMethod: AvailableShippingMethod) => {
        if (!shippingMethod || !shippingMethod.method_code) {
          throw new Error('Invalid shipping method');
        }

        const cartResponse = await shippingMethodsService.setShippingMethods({
          cart_id: cart.value?.id,
          shipping_methods: [
            {
              carrier_code: shippingMethod.carrier_code,
              method_code: shippingMethod.method_code
            }
          ]
        });

        if (!cartResponse.setShippingMethodsOnCart.cart) {
          throw new Error('Invalid response from setShippingMethods');
        }

        updateCartPartially(cartResponse.setShippingMethodsOnCart.cart);
        loadJpDeliveryDateData(shippingMethod.carrier_code);
      },

      saveDeliveryDateTime: async (date: string, time: string) => {
        isCartSaving.value = true;

        if (!cart.value?.id) {
          throw new Error('Cart ID is missing');
        }

        await shippingMethodsService.setDeliveryDateTime({
          cart_id: cart.value.id,
          delivery_date: date,
          delivery_time: time
        });

        isCartSaving.value = false;
        return true;
      }
    })
  });

  const shippingMethods = computed(
    () =>
      cart.value?.shipping_addresses
        ?.map((shippingAddress) => shippingAddress?.available_shipping_methods)
        ?.filter((item) => item)
        ?.flat() || []
  );

  const shippingMethodsWithDeliveryDates = computed(() => {
    if (!estimationDeliveryDates.value && !estimationDeliveryMessages.value) return shippingMethods.value || [];

    const deliveryDateMethodNames = Object.keys(estimationDeliveryDates.value || {});

    return (shippingMethods.value || []).map((shippingMethod) => {
      if (!shippingMethod) return shippingMethod;

      const matchedDeliveryDateMethodName = deliveryDateMethodNames.find((deliveryDateMethodName) => {
        if (!deliveryDateMethodName || !shippingMethod.method_title) return false;
        return shippingMethod.method_title.toLowerCase().includes(deliveryDateMethodName.toLowerCase());
      });

      if (matchedDeliveryDateMethodName) {
        if (estimationDeliveryMessages.value && estimationDeliveryMessages.value[matchedDeliveryDateMethodName]) {
          return {
            ...shippingMethod,
            shipping_date: estimationDeliveryMessages.value[matchedDeliveryDateMethodName]
          };
        } else if (estimationDeliveryDates.value && estimationDeliveryDates.value[matchedDeliveryDateMethodName]) {
          const date = estimationDeliveryDates.value[matchedDeliveryDateMethodName];

          return {
            ...shippingMethod,
            shipping_date: app.i18n.t('checkout.shipping_methods.delivery.estimate', {
              date: formatDate(date)
            }) as string
          };
        }
      }

      return shippingMethod;
    });
  });

  const selectedShippingMethod = computed(() => {
    if (!selectedShippingMethodCode.value) return null;

    return shippingMethodsWithDeliveryDates.value.find(({ method_code }) => method_code === selectedShippingMethodCode.value);
  });

  const getShippingMethodPrice = (shippingMethod: AvailableShippingMethod) => {
    if (!shippingMethod) return 0;

    if (shippingMethod.price_incl_tax?.value !== undefined) {
      return shippingMethod.price_incl_tax.value;
    }

    if (shippingMethod.amount?.value !== undefined) {
      return shippingMethod.amount.value;
    }

    return 0;
  };

  const deliveryDateInfo = computed<DeliveryDateInfo | null>(() => {
    if (!selectedShippingMethod.value?.delivery_date_info) {
      return null;
    }

    try {
      return JSON.parse(selectedShippingMethod.value.delivery_date_info);
    } catch (err) {
      Logger.error('Error parsing JSON:', err);
      return null;
    }
  });

  const standardShippingMethods = computed(() => {
    if (!shipping_categories_general_enabled) return [];
    if (!shippingMethodsWithDeliveryDates.value || !Array.isArray(shippingMethodsWithDeliveryDates.value)) return [];

    return shippingMethodsWithDeliveryDates.value
      .filter((method) => {
        if (!method || !method.method_code) return false;
        if (!Array.isArray(shipping_categories_additional_methods)) return true;

        return !shipping_categories_additional_methods.some((additionalMethod) => {
          if (!additionalMethod) return false;
          const methodPrefix = additionalMethod.split('_')[0];
          return method.method_code === additionalMethod || (methodPrefix && method.method_code.includes(methodPrefix));
        });
      })
      .map((method) => ({
        ...method,
        position: shipping_categories_standard_position
      }));
  });

  const additionalShippingMethods = computed(() => {
    if (!shipping_categories_general_enabled) return [];
    if (!shippingMethodsWithDeliveryDates.value || !Array.isArray(shippingMethodsWithDeliveryDates.value)) return [];
    if (!Array.isArray(shipping_categories_additional_methods)) return [];

    return shippingMethodsWithDeliveryDates.value
      .filter((method) => {
        if (!method || !method.method_code) return false;

        return shipping_categories_additional_methods.some((additionalMethod) => {
          if (!additionalMethod) return false;
          const methodPrefix = additionalMethod.split('_')[0];
          return method.method_code === additionalMethod || (methodPrefix && method.method_code.includes(methodPrefix));
        });
      })
      .map((method) => ({
        ...method,
        position: shipping_categories_additional_position
      }));
  });

  const shippingCategories = computed(() => {
    if (!shipping_categories_general_enabled) return null;

    return {
      standard: {
        methods: standardShippingMethods.value || [],
        position: shipping_categories_standard_position,
        title: shipping_categories_standard_name || 'Standard',
        icon: shipping_categories_standard_icon || ''
      },
      additional: {
        methods: additionalShippingMethods.value || [],
        position: shipping_categories_additional_position,
        title: shipping_categories_additional_name || 'Additional',
        icon: shipping_categories_additional_icon || ''
      }
    };
  });

  const sortedShippingMethods = computed<ShippingMethodCategory[]>(() => {
    const categories = shippingCategories.value;
    if (!categories) return [];

    const sortedMethods = [];

    // Helper function to add a category if it has methods
    const addCategoryIfHasMethods = (category: ShippingMethodCategory) => {
      if (!category) return;

      const methods = category.methods || [];
      if (methods.length > 0) {
        sortedMethods.push({
          title: category.title || '',
          icon: category.icon || '',
          methods: methods,
          position: category.position || '0'
        });
      }
    };

    addCategoryIfHasMethods(categories.standard);
    addCategoryIfHasMethods(categories.additional);

    // Sort by position with fallback handling
    return sortedMethods.sort((a, b) => {
      const posA = parseInt(a.position || '0', 10) || 0;
      const posB = parseInt(b.position || '0', 10) || 0;
      return posA - posB;
    });
  });

  const isShippingMethodStepCompleted = computed<boolean>(() => {
    return hasOnlyBooxiItemsInCart.value || !!selectedShippingMethod.value?.method_code;
  });

  const isDeliveryDateUnavailable = (date: Date) => {
    // First check if dates are available using the deliveryDateInfo
    if (deliveryDateInfo.value?.availableDeliveryDays) {
      if (!date || !(date instanceof Date)) {
        return true;
      }

      try {
        const preparedDate = new Date(date).setHours(0, 0, 0, 0);
        const fromDate = deliveryDateInfo.value.availableDeliveryDays.fromDate;
        const toDate = deliveryDateInfo.value.availableDeliveryDays.toDate;

        // Use current date as fallback if dates are invalid
        const today = new Date().setHours(0, 0, 0, 0);

        const from = fromDate ? new Date(fromDate).setHours(0, 0, 0, 0) : today;
        const to = toDate ? new Date(toDate).setHours(0, 0, 0, 0) : today;

        return preparedDate < from || preparedDate > to;
      } catch (error) {
        Logger.error('Error checking date availability:', error);

        return true;
      }
    }

    // Otherwise fall back to JP delivery date logic if applicable
    if (jpDeliveryFromDate.value || jpDeliveryToDate.value) {
      return isInvalidDeliveryDate(date, jpDeliveryFromDate.value, jpDeliveryToDate.value);
    }

    return true;
  };

  // JP delivery date functions
  const loadJpDeliveryDateData = (selectedMethodCode) => {
    if (!isJpStore.value || !selectedMethodCode || !shippingMethods.value || shippingMethods.value.length === 0) {
      return false;
    }

    const deliveryDateData = getDeliveryDateData(selectedMethodCode);
    if (!deliveryDateData || !Object.keys(deliveryDateData).length) {
      return false;
    }

    jpDeliveryTimeList.value = [];
    if (deliveryDateData.deliveryTime && Array.isArray(deliveryDateData.deliveryTime)) {
      deliveryDateData.deliveryTime.forEach((timeLabel) => {
        jpDeliveryTimeList.value.push(app.i18n.t(timeLabel) as string);
      });
    }

    jpDeliveryFromDate.value = deliveryDateData.availableDeliveryDays?.fromDate || '';
    jpDeliveryToDate.value = deliveryDateData.availableDeliveryDays?.toDate || '';

    return true;
  };

  const getDeliveryDateData = (selectedMethodCode: string) => {
    if (!selectedMethodCode || !shippingMethods.value || !shippingMethods.value.length) {
      return null;
    }

    const deliveryDateDataString = shippingMethods.value.find((sm) => sm.carrier_code === selectedMethodCode)?.delivery_date_info;

    if (!deliveryDateDataString) {
      return null;
    }

    try {
      const deliveryDateData = JSON.parse(deliveryDateDataString);
      if (deliveryDateData && Object.keys(deliveryDateData).length > 0) {
        if (deliveryDateData.availableMethod && !deliveryDateData.availableMethod.includes(selectedMethodCode)) {
          return null;
        }

        return deliveryDateData;
      }
    } catch (error) {
      Logger.error('Error parsing delivery date data:', error);
    }

    return null;
  };

  const selectShippingMethod = async (method: AvailableShippingMethod) => {
    if (!method) return;

    try {
      await methodsWithExecute.saveSelectedShippingMethod(method);
    } catch (error) {
      Logger.error('Error selecting shipping method:', error);
    }
  };

  const getShippingCategoriesEnabled = () => shipping_categories_general_enabled;

  return {
    ...methodsWithExecute,
    loading,
    errors,
    overallLoading,
    shippingMethods: shippingMethodsWithDeliveryDates,
    selectedShippingMethod,
    deliveryDateInfo,
    getShippingMethodPrice,
    getEstimatedDeliveryDates,
    standardShippingMethods,
    additionalShippingMethods,
    shippingCategories,
    sortedShippingMethods,
    getShippingCategoriesEnabled,
    isDeliveryDateUnavailable,
    isShippingMethodStepCompleted,
    selectShippingMethod,
    jpDateFormat,
    jpDeliveryTimeList,
    isCartSaving,
    loadJpDeliveryDateData
  };
};
