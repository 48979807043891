import { useUser } from '@checkout/composables';
import type { EventsExtraParams, ExtraEventParams } from '@form-generator';
import { ref } from '@nuxtjs/composition-api';

export const useEmailRecognitionEvents = () => {
  const {
    checkIsEmailAvailable,
    loading: { checkIsEmailAvailable: checkIsEmailAvailableLoading }
  } = useUser();

  const isEmailRecognized = ref(false);
  const showEmailRecognitionMessage = ref(false);

  const getEmailRecognitionEvents = (email: string) => ({
    input: () => {
      showEmailRecognitionMessage.value = false;
    },
    change: async (e: Event, extraParams: ExtraEventParams) => {
      if (email && extraParams.fieldValidationData?.valid && !checkIsEmailAvailableLoading.value) {
        isEmailRecognized.value = !(await checkIsEmailAvailable(email));
      } else {
        isEmailRecognized.value = false;
      }
      showEmailRecognitionMessage.value = true;
    }
  });

  const eventsExtraParams: EventsExtraParams = {
    fieldValidationData: true
  };

  return {
    isEmailRecognized,
    showEmailRecognitionMessage,
    getEmailRecognitionEvents,
    eventsExtraParams
  };
};
