import { useContext } from '@nuxtjs/composition-api';

import { useConfig } from '~/composables';

export function useMagentoState() {
  const { app } = useContext();
  const { config } = useConfig();
  const configState = app.context.$vsf.$magento.config.state;

  const setCartId = (cartId?: string | null): void => {
    if (cartId) {
      configState.setCartId(cartId);
    } else {
      configState.setCartId();
    }
  };

  const getCartId = (): string => {
    return configState.getCartId();
  };

  const removeCartId = (): void => {
    configState.removeCartId();
  };

  const setCustomerToken = (token?: string, rememberUser = false): void => {
    const apiState = app.context.$vsf.$magento.config.state;
    const maxAgeInHours = config.value?.customer_access_token_lifetime || 24;
    const hoursInSeconds = 60 * 60;

    apiState.setCustomerToken(token, rememberUser ? { maxAge: maxAgeInHours * hoursInSeconds } : undefined);
  };

  const getCustomerToken = (): string => {
    return configState.getCustomerToken();
  };

  const removeCustomerToken = (): void => {
    configState.removeCustomerToken();
  };

  const getStore = (): string => {
    return configState.getStore();
  };

  return {
    setCartId,
    getCartId,
    removeCartId,
    setCustomerToken,
    getCustomerToken,
    removeCustomerToken,
    getStore
  };
}
