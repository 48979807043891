

















import UserLoginForm from '@checkout-organisms/auth/login-form/UserLoginForm.vue';
import { defineComponent, ref } from '@nuxtjs/composition-api';
import VaimoSocialLogin from 'organisms/VaimoSocialLogin.vue';

export default defineComponent({
  name: 'LoginSection',
  components: {
    VaimoSocialLogin,
    ForgotPasswordForm: () =>
      import(
        '@checkout-organisms/auth/forgot-password-form/ForgotPasswordForm.vue'
      ),
    SideModal: () => import('@checkout-atoms/side-modal/SideModal.vue'),
    UserLoginForm
  },
  setup() {
    const isForgotPasswordFormOpen = ref(false);

    const openForgotPasswordForm = () => {
      isForgotPasswordFormOpen.value = true;
    };

    const closeForgotPasswordForm = () => {
      isForgotPasswordFormOpen.value = false;
    };

    return {
      isForgotPasswordFormOpen,
      openForgotPasswordForm,
      closeForgotPasswordForm
    };
  }
});
