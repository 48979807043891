





















import { usePayment } from '@checkout/composables';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfRadio } from '@storefront-ui/vue';

export default defineComponent({
  name: 'NpPaymentProvider',
  components: {
    SfRadio
  },
  emits: ['status'],
  setup(_props, { emit }) {
    const { setPaymentMethod, paymentMethods, selectedPaymentMethodCode } =
      usePayment();

    const allowedPaymentMethodsCodes = ['veriteworks_cod'];

    const allowedPaymentMethods = computed(() =>
      paymentMethods.value.filter((item) =>
        allowedPaymentMethodsCodes.includes(item.code)
      )
    );

    const definePaymentMethods = async (paymentMethodCode: string) => {
      await setPaymentMethod(paymentMethodCode);
      emit('status', paymentMethodCode);
    };

    return {
      allowedPaymentMethods,
      selectedPaymentMethodCode,
      definePaymentMethods
    };
  }
});
