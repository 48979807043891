




































import { useCart } from '@checkout/composables';
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

import cartGetters from '~/helpers/getters/cartGetters';

import {
  CartTotals,
  OrderSummaryProduct,
  OrderSummaryTemplate
} from './_internal';

export default defineComponent({
  name: 'OrderSummary',
  components: {
    OrderSummaryTemplate,
    SfLink,
    CartTotals,
    OrderSummaryProduct
  },
  setup() {
    const { cart } = useCart();
    const giftWrappingPrice = computed<number | null>(() =>
      cartGetters.getGiftWrappingPrice(cart.value)
    );

    const shippingPrice = computed<number | null>(() => {
      const selectedShippingMethod = cartGetters.getSelectedShippingMethod(
        cart.value
      );

      return cartGetters.getShippingMethodPrice(selectedShippingMethod);
    });

    const signatureGiftBox = computed<number | null>(() =>
      cartGetters.getSignatureGiftBox(cart.value)
    );

    const hasWelcomeGift = computed(() => cart.value?.has_promo_item);

    return {
      cart,
      cartItems: computed(() => cart.value?.items || []),
      shippingPrice,
      giftWrappingPrice,
      signatureGiftBox,
      hasWelcomeGift
    };
  }
});
