import { render, staticRenderFns } from "./ShippingMethodsList.vue?vue&type=template&id=9d8cf1c2&scoped=true&"
import script from "./ShippingMethodsList.vue?vue&type=script&lang=ts&"
export * from "./ShippingMethodsList.vue?vue&type=script&lang=ts&"
import style0 from "./ShippingMethodsList.vue?vue&type=style&index=0&id=9d8cf1c2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d8cf1c2",
  null
  
)

export default component.exports