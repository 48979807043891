import { ref } from '@nuxtjs/composition-api';

import { scrollToElement } from '~/helpers/scrollToElement';

import type { AuthOptionsType, UseAuthStep } from './useAuthStep';

export const useAuthStep = (): UseAuthStep => {
  const selectedAuthOption = ref<AuthOptionsType | null>(null);

  const selectAuthOption = (type: AuthOptionsType): void => {
    selectedAuthOption.value = type;
    // Scroll to Auth step accordion item on form change
    scrollToElement('#auth-step');
  };

  const resetAuthOption = (): void => {
    selectedAuthOption.value = null;
  };

  return {
    selectedAuthOption,
    resetAuthOption,
    selectAuthOption
  };
};
