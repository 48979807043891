import type { Ref } from '@nuxtjs/composition-api';

export interface UseAuthStep {
  selectedAuthOption: Ref<AuthOptionsType | null>;
  resetAuthOption: () => void;
  selectAuthOption: (type: AuthOptionsType | null) => void;
}

export type AuthOptionsType = typeof AuthOptions[keyof typeof AuthOptions];

export const AuthOptions = {
  SIGN_IN: 'sign-in',
  SIGN_UP: 'sign-up',
  GUEST: 'as-guest'
} as const;
