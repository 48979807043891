export const CartTotalsSectionsEnum = {
  SUBTOTAL_PRICE: 'subtotal-price',
  TAXES: 'taxes',
  FEES: 'fees',
  DISCOUNT: 'discount',
  SHIPPING_PRICE: 'shipping-price',
  COD_FEES: 'cod-fees',
  WELCOME_GIFT: 'welcome-gift',
  SIGNATURE_GIFT_BOX: 'signature-gift-box'
} as const;
