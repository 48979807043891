






























































import { useAuth, useUser } from '@checkout/composables';
import { useAuthStep } from '@checkout/composables/useAuthStep';
import { AuthOptions } from '@checkout/composables/useAuthStep/useAuthStep';
import { useUserStore } from '@checkout/stores';
import GuestUserForm from '@checkout-organisms/auth/guest-user-form/GuestUserForm.vue';
import { defineComponent, watch } from '@nuxtjs/composition-api';
import VaimoRadio from 'molecules/VaimoRadio.vue';
import { storeToRefs } from 'pinia';

import type { User } from '~/models';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export default defineComponent({
  name: 'AuthStep',
  components: {
    GuestUserForm,
    RegistrationSection: () =>
      import(
        '@checkout-organisms/auth/registration-section/RegistrationSection.vue'
      ),
    LoginSection: () =>
      import('@checkout-organisms/auth/login-section/LoginSection.vue'),
    VaimoRadio,
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    UserInfo: () => import('@checkout-organisms/auth/user-info/UserInfo.vue')
  },
  props: {
    isActive: {
      type: Boolean
    }
  },
  emits: ['next'],
  setup(props, { emit }) {
    const { selectedAuthOption, selectAuthOption, resetAuthOption } =
      useAuthStep();
    const { isAuthenticated } = useAuth();
    const { userEmail } = useUser();

    const { user: oldUser, isLoggedIn } = storeToRefs(useCustomerStore());
    const userStore = useUserStore();
    const { isGuestUser } = storeToRefs(userStore);

    userStore.setUser(oldUser as User);
    userStore.setLoginStatus(isLoggedIn.value);

    if (oldUser.value?.addresses) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TODO: sync stores correctly
      userStore.setUserAddresses(oldUser.value.addresses);
    }

    watch(
      () => props.isActive,
      (isActive) => {
        if (isActive && !isAuthenticated.value && userEmail.value) {
          selectAuthOption(AuthOptions.GUEST);
        }
      }
    );

    watch(
      [isAuthenticated, isGuestUser],
      ([isAuthenticatedValue, isGuestUserValue]) => {
        if (isAuthenticatedValue || isGuestUserValue) {
          emit('next');
        }
      },
      {
        immediate: true
      }
    );

    return {
      selectedAuthOption,
      selectAuthOption,
      resetAuthOption,
      AuthOptions,

      isAuthenticated,
      userEmail
    };
  }
});
