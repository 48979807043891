import { ApiService } from '@checkout/services/core';
import type { NuxtAppOptions } from '@nuxt/types';
import type { ApiClientMethods } from '@vue-storefront/core';

import customCountries from '~/diptyqueTheme/customQueries/magento/customCountries';
import storeAllowedCountriesList from '~/diptyqueTheme/customQueries/magento/storeAllowedCountriesList';
import type { AllowedCountriesQuery, CountriesApiMethods, CountriesQuery, CountryInformationQuery } from '~/models';

export class CountriesService extends ApiService<ApiClientMethods<CountriesApiMethods>> {
  constructor(context: NuxtAppOptions) {
    super(context.$vsf.$magento.api);
  }

  getAllowedCountries(): Promise<AllowedCountriesQuery> {
    return this.handleApiResponse(this.api.customQuery({ query: storeAllowedCountriesList }));
  }

  getCountries(): Promise<CountriesQuery> {
    return this.handleApiResponse(this.api.customQuery({ query: customCountries.loc.source.body }));
  }

  getCountryById(id: string): Promise<CountryInformationQuery> {
    return this.handleApiResponse(this.api.country(id));
  }
}
