








































import { useCountriesStore } from '@checkout/stores/countries/countries.store';
import type { PropType } from '@nuxtjs/composition-api';
import { useContext } from '@nuxtjs/composition-api';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import VaimoButton from 'atoms/VaimoButton.vue';
import { storeToRefs } from 'pinia';

import { useStore } from '~/composables';
import type { Address } from '~/models';

export default defineComponent({
  name: 'AddressOption',
  components: {
    VaimoButton
  },
  props: {
    data: {
      type: Object as PropType<Address>,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  emits: ['edit', 'select'],
  setup(props, { emit }) {
    const { isJpStore } = useStore();
    const countriesStore = useCountriesStore();
    const { allCountries, allowedCountries } = storeToRefs(countriesStore);
    const { i18n } = useContext();

    const handleClick = () => {
      emit('select');
    };

    const editAddress = () => {
      emit('edit');
    };

    const getCountryNameByCode = (code: string) => {
      const countries = allCountries.value?.length
        ? allCountries.value || []
        : allowedCountries.value.countries || [];

      return countries?.find((country) => country.id === code)?.label || '';
    };

    const address = computed(() =>
      isJpStore.value
        ? {
            default_shipping: props.data.default_shipping || false,
            default_billing: props.data.default_billing || false,
            fullname: `${props.data.firstname} ${props.data.lastname}`,
            street: props.data.street?.[0] || '',
            city: props.data.postcode
              ? `${props.data.postcode}, ${props.data.city}`
              : props.data.city,
            country: getCountryNameByCode(props.data.country_code),
            telephone: props.data.telephone || ''
          }
        : {
            default_shipping: props.data.default_shipping || false,
            default_billing: props.data.default_billing || false,
            fullname: `${props.data.firstname} ${props.data.lastname}`,
            street: props.data.street?.[0] || '',
            city: props.data.postcode
              ? `${props.data.postcode}, ${props.data.city}`
              : props.data.city,
            country: getCountryNameByCode(props.data.country_code),
            telephone: props.data.telephone || ''
          }
    );

    const isDefaultAddress = computed(() => {
      return address.value.default_shipping || address.value.default_billing;
    });

    const defaultAddressLabel = computed(() => {
      if (address.value.default_shipping) {
        return i18n.t('checkout.shipping.default_address.shipping');
      } else if (address.value.default_billing) {
        return i18n.t('checkout.shipping.default_address.billing');
      }
    });

    return {
      handleClick,
      editAddress,
      address,
      isDefaultAddress,
      defaultAddressLabel
    };
  }
});
