































































































































































import { useAuth } from '@checkout/composables';
import { CUSTOMER_DATA_TO_GET_ORDER } from '@checkout/constants';
import { useUserStore } from '@checkout/stores';
import type { GuestOrderResponse } from '@checkout/types';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  useContext,
  useMeta,
  useRoute,
  useRouter
} from '@nuxtjs/composition-api';
import { SfHeading, SfProperty } from '@storefront-ui/vue';
import NostoThankYou from 'atoms/NostoThankYou.vue';
import VaimoBadge from 'atoms/VaimoBadge.vue';
import VaimoEngravingCard from 'organisms/cart/parts/VaimoEngravingCard.vue';
import VaimoContentBlock from 'organisms/VaimoContentBlock.vue';
import { storeToRefs } from 'pinia';
import LoyaltyPushRenderer from 'templates/sections/LoyaltyPush/LoyaltyPushRenderer.vue';

import { useApi, useStore } from '~/composables';
import { useGoogleTagManager } from '~/diptyqueTheme/composable';
import { formatEventPrice } from '~/diptyqueTheme/composable/useGoogleTagManager/helpers';
import guestOrderQuery from '~/diptyqueTheme/customQueries/magento/guestOrder';
import setImaginoFirstPurchaseDateQuery from '~/diptyqueTheme/customQueries/magento/setImaginoFirstPurchaseDate';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import { Logger } from '~/helpers/logger';
import { useCart } from '~/modules/core/composables/useCart';
import orderGetters from '~/modules/core/getters/orderGetters';
import { useUserOrder } from '~/modules/customer/composables/useUserOrder';

export default defineComponent({
  name: 'ThankYouStep',
  components: {
    SfProperty,
    SfHeading,
    VaimoContentBlock,
    VaimoBadge,
    VaimoEngravingCard,
    LoyaltyPushRenderer,
    NostoThankYou,
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    JpSuccessCheckoutTotals: () =>
      import('@checkout-molecules/JpSuccessCheckoutTotals.vue')
  },
  scrollToTop: true,
  setup() {
    const {
      app: { localeRoute, i18n, $gtm }
    } = useContext();
    const { isJpStore } = useStore();
    const route = useRoute();
    const router = useRouter();
    const { isAuthenticated } = useAuth();
    const userStore = useUserStore();
    const { user, isUserLoggedIn } = storeToRefs(userStore);
    const { search } = useUserOrder();
    const { cart, load } = useCart();
    const orderData = ref([]);

    const orderNumber = computed(() => route.value.query?.order ?? '');
    const customerName = computed(() => user.value?.firstname ?? '');
    const totals = ref({
      discount: null,
      subTotal: null,
      subTotalInclTax: null,
      grandTotal: null,
      tax: null,
      shipping: null,
      shippingInclTax: null,
      vwCodFeeInclTax: null
    });
    const shippingMethod = ref(null);
    const products = ref(null);
    const isGiftWrappingSet = ref(null);
    const isGiftMessageSet = ref(null);
    const appliedCouponCode = ref(null);
    const appliedCouponValue = ref(null);
    const splioSrc = ref(null);
    const { query } = useApi();
    const guestData = ref<{
      firstname?: string;
      lastname?: string;
      firstnamekana?: string;
      lastnamekana?: string;
      email?: string;
      mobile?: string;
    }>({});

    // GTM
    const { getPurchaseDetails, EVENT_TIMEOUT } = useGoogleTagManager();

    const customerDataToGetOrder = ref(null);

    const ctaAction = () => {
      if (!isAuthenticated.value) {
        return router.push(localeRoute({ name: 'home' }));
      }
      return router.push(localeRoute({ name: 'customer-order-history' }));
    };

    const getFirstname = () => {
      if (isAuthenticated.value) {
        return customerName.value;
      }
      return user.value?.firstname ?? '';
    };

    const getIsPreOrder = (item) => {
      return item.preorder;
    };

    const getPreOrderLabel = (item) => {
      return item.preorderStockNotice;
    };

    // Refs - Order summary
    const orderSummary = ref(null);
    const scrollToOrderSummary = () => {
      if (orderSummary.value) {
        orderSummary.value.scrollIntoView({
          behavior: 'smooth'
        });
      }
    };

    const getShippingMethod = (method: string): string => {
      return method ?? '';
    };

    const getTotalsData = (total) => {
      return {
        discount: total?.discounts?.[0]?.amount?.value ?? null,
        subTotal: total?.subtotal?.value ?? null,
        subTotalInclTax: total?.subtotal_incl_tax?.value ?? null,
        grandTotal: total?.base_grand_total?.value ?? null,
        tax: total?.total_tax?.value ?? null,
        shipping: total?.shipping_handling?.amount_excluding_tax?.value ?? null,
        shippingInclTax:
          total?.shipping_handling?.amount_including_tax?.value ?? null,
        vwCodFeeInclTax: total?.vw_cod_fee?.amount_including_tax?.value ?? null
      };
    };

    const gtmPurchaseEvent = () => {
      try {
        setTimeout(() => {
          $gtm.push(
            getPurchaseDetails(
              products.value,
              totals.value,
              isGiftWrappingSet.value,
              isGiftMessageSet.value,
              appliedCouponCode.value,
              appliedCouponValue.value
                ? formatEventPrice(appliedCouponValue.value)
                : '',
              shippingMethod.value,
              orderNumber.value?.toString()
            )
          );
        }, EVENT_TIMEOUT);
      } catch (e) {
        Logger.error(
          `error pushing gtm event: purchase: ${e}\n\nOrder Number:${orderNumber.value}`
        );
      }
    };

    onMounted(async () => {
      if (isUserLoggedIn.value) {
        orderData.value = await search({
          filter: {
            number: { eq: orderNumber.value?.toString() }
          },
          customQuery: {
            customerOrders: 'customerOrdersWithProductImg'
          }
        }).then((res) => {
          const {
            items,
            total,
            shipping_method,
            is_gift_wrapping_set,
            is_gift_message_set,
            applied_coupon,
            splio_src
          } = res?.items?.[0] ?? {};

          products.value = items ?? null;
          shippingMethod.value = getShippingMethod(shipping_method);
          isGiftWrappingSet.value = is_gift_wrapping_set;
          isGiftMessageSet.value = is_gift_message_set;
          appliedCouponCode.value = applied_coupon?.coupon_code ?? '';
          appliedCouponValue.value = getTotalsData(total)?.discount ?? '';
          splioSrc.value = splio_src;
          totals.value = getTotalsData(total);

          return res?.items ?? null;
        });

        await query(setImaginoFirstPurchaseDateQuery, {
          order_increment_id: orderNumber.value
        });
      } else {
        const storedDataToGetOrder = sessionStorage.getItem(
          CUSTOMER_DATA_TO_GET_ORDER
        );

        if (storedDataToGetOrder) {
          customerDataToGetOrder.value = JSON.parse(storedDataToGetOrder);
        }

        if (
          !customerDataToGetOrder.value?.customerEmail ||
          !customerDataToGetOrder.value?.customerLastName
        ) {
          return;
        }

        const { data: guestOrderData, errors: guestOrderErrors } =
          await query<GuestOrderResponse>(guestOrderQuery, {
            number: orderNumber.value?.toString(),
            email: customerDataToGetOrder.value?.customerEmail,
            lastname: customerDataToGetOrder.value?.customerLastName
          });

        if (guestOrderErrors?.length > 0) {
          throw new Error(String(guestOrderErrors[0].message));
        }

        const {
          guestOrder: {
            items,
            total,
            shipping_method,
            is_gift_wrapping_set,
            is_gift_message_set,
            applied_coupon,
            splio_src,
            customer_firstname,
            customer_lastname,
            customer_firstnamekana,
            customer_lastnamekana,
            customer_email,
            customer_telephone
          }
        } = guestOrderData ?? {};

        products.value = items ?? null;
        shippingMethod.value = getShippingMethod(shipping_method);
        totals.value = getTotalsData(total);
        isGiftWrappingSet.value = is_gift_wrapping_set;
        isGiftMessageSet.value = is_gift_message_set;
        appliedCouponCode.value = applied_coupon?.coupon_code ?? '';
        appliedCouponValue.value = getTotalsData(total)?.discount ?? '';
        splioSrc.value = splio_src;

        orderData.value = [guestOrderData?.guestOrder ?? null];
        guestData.value = {
          firstname: customer_firstname ?? null,
          lastname: customer_lastname ?? null,
          firstnamekana: customer_firstnamekana ?? null,
          lastnamekana: customer_lastnamekana ?? null,
          email: customer_email ?? null,
          mobile: customer_telephone ?? null
        };
      }

      if (!cart.value?.id) {
        await load();
      }

      gtmPurchaseEvent();
    });

    const metaTags = computed(() => ({
      title: `${i18n.t('Checkout')} | Diptyque Paris`,
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          content: `${i18n.t('Checkout')} | Diptyque Paris`
        },
        {
          hid: 'robots',
          property: 'robots',
          content: 'noindex, nofollow'
        }
      ]
    }));

    useMeta(metaTags.value);

    const isEngraving = (product) => {
      return product.engraving ? true : false;
    };

    onBeforeUnmount(() => {
      if (!isAuthenticated.value) {
        sessionStorage.removeItem(CUSTOMER_DATA_TO_GET_ORDER);
      }
    });

    return {
      isAuthenticated,
      orderGetters,
      getFirstname,
      ctaAction,
      orderNumber,
      customerName,
      orderData,
      guestData,
      totals,
      user,
      products,
      isJpStore,
      orderSummary,
      scrollToOrderSummary,
      validateFraction,
      splioSrc,
      getPreOrderLabel,
      getIsPreOrder,
      isEngraving
    };
  },
  head: {}
});
