import { useCart, useShippingAddress, useShippingMethod } from '@checkout/composables';
import { useAsyncMethods } from '@checkout/composables/core';
import { ShippingAddressesService, ShippingMethodsService } from '@checkout/services';
import { computed, ref, useContext } from '@nuxtjs/composition-api';

import { Logger } from '~/helpers/logger';
import type { Boutique, BoutiqueResponse, CountryCodeEnum } from '~/models';

export const useBoutique = () => {
  const { shippingAddress } = useShippingAddress();
  const { selectedShippingMethod } = useShippingMethod();
  const { cart, updateCartPartially } = useCart();
  const { app } = useContext();

  const shippingAddressesService = new ShippingAddressesService(app);
  const shippingMethodsService = new ShippingMethodsService(app);

  const selectedBoutique = ref<Boutique | null>(null);
  const toggleBoutiquesList = ref<boolean>(false);

  const boutiquesList = computed<Boutique[]>(() => {
    if (!selectedShippingMethod.value?.additional_data) {
      return [];
    }

    try {
      const additionalData = JSON.parse(selectedShippingMethod.value.additional_data);

      if (!additionalData?.boutique_list) {
        return [];
      }

      return Object.values(additionalData?.boutique_list).map((boutique: BoutiqueResponse) => ({
        id: boutique.external_id,
        name: boutique.name,
        address: boutique.address,
        city: boutique.city,
        postcode: boutique.zip_code,
        distance: boutique.distance
      }));
    } catch (err) {
      Logger.error('Error parsing JSON:', err);

      return [];
    }
  });

  const {
    methods: methodsWithExecute,
    loading,
    errors,
    overallLoading
  } = useAsyncMethods({
    composableName: 'useBoutique',
    methodsFactory: () => ({
      async saveSelectedBoutique(boutique: Boutique) {
        const shippingAddressPromise = shippingAddressesService.setShippingAddresses({
          cart_id: cart.value?.id,
          shipping_addresses: [
            {
              address: {
                city: boutique.city,
                postcode: boutique.postcode,
                firstname: shippingAddress.value.firstname,
                lastname: shippingAddress.value.lastname,
                street: shippingAddress.value.street,
                telephone: shippingAddress.value.telephone,
                country_code: shippingAddress.value.country.code as CountryCodeEnum,
                region_id: shippingAddress.value.region.region_id,
                save_in_address_book: false,
                default_shipping: false
              }
            }
          ]
        });

        const clickAndCollectPromise = shippingMethodsService.setClickAndCollectShop({
          cart_id: cart.value?.id,
          shopInput: {
            external_id: boutique.id
          }
        });

        const [cartResponse] = await Promise.all([shippingAddressPromise, clickAndCollectPromise]);

        updateCartPartially(cartResponse.setShippingAddressesOnCart.cart);
      }
    })
  });

  const setSelectedBoutique = (boutique: Boutique) => {
    selectedBoutique.value = { ...boutique };
    toggleBoutiquesList.value = false;
  };

  const getFormatedDistance = (boutique: Boutique, isUsCaStore = false) => {
    if (!boutique || typeof boutique.distance !== 'number') {
      return '0 km';
    }

    const distanceUnit = isUsCaStore ? 'mi' : 'km';
    const DIVIDER = 1000;
    return `${(boutique.distance / DIVIDER).toFixed(1)} ${distanceUnit}`;
  };

  return {
    ...methodsWithExecute,
    loading,
    errors,
    overallLoading,

    // Computed properties
    boutiquesList,
    selectedBoutique,
    toggleBoutiquesList,

    // Methods
    setSelectedBoutique,
    getFormatedDistance
  };
};
