import { ref, useContext } from '@nuxtjs/composition-api';

import { useUiNotification } from '~/composables';
import mask from '~/composables/utils/mask';
import { Logger } from '~/helpers/logger';

import type { AsyncTaskOptions, AsyncTaskResult } from './models';

export function useAsyncTask<TArgs extends unknown[], TResult>({
  composableName,
  methodName,
  fn,
  maskArgIndices = [],
  defaultErrorNotificationConfig = {},
  onError
}: AsyncTaskOptions<TArgs, TResult>): AsyncTaskResult<TArgs, TResult> {
  const { app } = useContext();
  const { send: sendNotification } = useUiNotification();
  const loading = ref<boolean>(false);
  const error = ref<Error | null>(null);

  const execute = async (...args: TArgs): Promise<TResult> => {
    loading.value = true;
    error.value = null;

    // Apply masking to specified arguments
    const loggedArgs = args.map((arg, index) => (maskArgIndices.includes(index) ? mask(arg) : arg));

    Logger.debug(`${composableName}/${methodName}:`, ...loggedArgs);

    try {
      return await fn(...args);
    } catch (err) {
      error.value = err as Error;
      Logger.error(`ERROR: ${composableName}/${methodName}`, err);
      if (onError) {
        onError(err);
      } else {
        // Default error handling
        const { icon, message, persist, title, type, area, ...restOptions } = defaultErrorNotificationConfig;

        sendNotification({
          icon: icon || 'error',
          id: Symbol(),
          message: message || (app.i18n.t('common.error.message') as string),
          persist: persist || false,
          title: title || 'Unexpected error',
          type: type || 'danger',
          area: area || 'top',
          ...restOptions
        });
      }
    } finally {
      loading.value = false;
    }
  };

  return {
    execute,
    loading,
    error
  };
}
