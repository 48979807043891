
















































import type { PropType } from '@nuxtjs/composition-api';
import { computed, defineComponent } from '@nuxtjs/composition-api';

import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import type { AvailableShippingMethod } from '~/models';

export default defineComponent({
  name: 'ShippingMethodItem',
  props: {
    method: {
      type: Object as PropType<AvailableShippingMethod>,
      required: true
    },
    selectedMethodCode: {
      type: String,
      default: ''
    },
    clickAndCollectMethodName: {
      type: String,
      required: true
    },
    boutiquesList: {
      type: Array,
      default: () => []
    }
  },
  emits: ['select'],
  setup(props, { emit }) {
    const shouldShowBoutiques = computed(
      () =>
        props.method.method_code === props.clickAndCollectMethodName &&
        props.boutiquesList.length > 0
    );

    const getShippingPrice = (method: AvailableShippingMethod): number => {
      if (!method) return 0;
      return method.amount.value || method.price_incl_tax.value || 0;
    };

    const formatPrice = (value: number) => {
      return validateFraction(value);
    };

    const handleMethodSelect = () => {
      emit('select', props.method);
    };

    return {
      shouldShowBoutiques,
      getShippingPrice,
      formatPrice,
      handleMethodSelect
    };
  }
});
