import { useUser } from '@checkout/composables';
import type { ForgotPasswordForm } from '@checkout-organisms/auth/forgot-password-form/models';
import type { Field } from '@form-generator';
import { useFields } from '@form-generator';
import { computed, ref } from '@nuxtjs/composition-api';

export const useForgotPasswordForm = () => {
  const formData = ref<ForgotPasswordForm>({
    email: ''
  });

  const { extendField, getEmailField } = useFields();

  const formFields = computed<Field[]>(() => [
    extendField(getEmailField('email'), {
      attributes: {
        class: 'input--with-label',
        autocomplete: 'username'
      }
    })
  ]);

  const {
    resetPassword,
    loading: { resetPassword: submitLoading }
  } = useUser();

  const isFormSubmitted = ref(false);

  const onSubmit = async (data: ForgotPasswordForm) => {
    await resetPassword(data);
    isFormSubmitted.value = true;
  };

  return {
    formData,
    formFields,
    onSubmit,
    submitLoading,
    isFormSubmitted
  };
};
