import { ApiService } from '@checkout/services/core';
import type { NuxtAppOptions } from '@nuxt/types';
import type { CustomQuery } from '@vue-storefront/core';

import type { CheckoutApiMethods, PlaceOrderInput, PlaceOrderMutation } from '~/models';
import type { ApiClientMethods } from '~/types/core';

export class OrderService extends ApiService<ApiClientMethods<CheckoutApiMethods>> {
  constructor(context: NuxtAppOptions) {
    super(context.$vsf.$magento.api);
  }

  placeOrder(input: PlaceOrderInput, customQuery?: CustomQuery): Promise<PlaceOrderMutation> {
    return this.handleApiResponse(this.api.placeOrder(input, customQuery));
  }
}
