import { useAuth, useShippingAddress, useUserAddress } from '@checkout/composables';
import { useAddressTransformer } from '@checkout/composables/useAddressTransformer';
import { useCartStore } from '@checkout/stores';
import { useCheckoutStore } from '@checkout/stores/checkout.store';
import type { AddressForm } from '@checkout-organisms/shipping-step/models';
import { computed, ref } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';

import type { AddressType } from '~/models';
import { AddressTypeEnum } from '~/models';

export function useShippingStep() {
  const checkoutStore = useCheckoutStore();
  const {
    isBillingAddressFormVisible: isBillingFormVisible,
    isShippingAddressFormVisible: isShippingFormVisible,
    shippingAddressId
  } = storeToRefs(checkoutStore);
  const cartStore = useCartStore();
  const { shippingAddresses, hasOnlyBooxiItemsInCart } = storeToRefs(cartStore);
  const { isAuthenticated } = useAuth();
  const { addressFormToAddressInput, cartAddressToCartShippingAddressInput } = useAddressTransformer();
  const { addAddress, overallLoading: isSaveUserAddressLoading } = useUserAddress();
  const {
    saveShippingAddressForm,
    saveBillingAddressForm,
    saveSelectedBillingAddress,
    overallLoading: isSaveGuestAddressLoading
  } = useShippingAddress();
  const { isBillingAddressSameAsShipping, guestShippingAddress, guestBillingAddress } = storeToRefs(checkoutStore);

  const shippingAddressForm = ref<AddressForm>(guestShippingAddress.value);
  const billingAddressForm = ref<AddressForm>(guestBillingAddress.value);

  const isShippingSectionValid = ref(false);
  const isBillingSectionValid = ref(isBillingAddressSameAsShipping.value);

  const showValidationMessage = ref(false);

  const areDataCorrect = computed<boolean>(() => {
    const isBillingValid = !isBillingFormVisible.value || isBillingSectionValid.value;
    const isShippingValid = !isShippingFormVisible.value || isShippingSectionValid.value;

    return isBillingValid && isShippingValid;
  });

  const isShippingMethodsVisible = computed<boolean>(() => {
    return isAuthenticated.value ? !!shippingAddressId.value : shippingAddresses.value.length > 0;
  });

  const setAddressSectionValidationStatus = (addressType: AddressType, value: boolean) => {
    if (addressType === AddressTypeEnum.BILLING) {
      isBillingSectionValid.value = value;
    } else if (addressType === AddressTypeEnum.SHIPPING) {
      isShippingSectionValid.value = value;
    }
  };

  const toggleIsBillingAddressSameAsShipping = () => {
    checkoutStore.setIsBillingAddressSameAsShipping(!isBillingAddressSameAsShipping.value);

    if (isBillingAddressSameAsShipping.value) {
      updateAddressForm(AddressTypeEnum.BILLING, shippingAddressForm.value);
      setAddressSectionValidationStatus(AddressTypeEnum.BILLING, isShippingSectionValid.value);
    } else {
      updateAddressForm(AddressTypeEnum.BILLING, guestBillingAddress.value);
    }

    checkoutStore.setAddressFormVisibility(AddressTypeEnum.BILLING, !isBillingAddressSameAsShipping.value);
  };

  const updateAddressForm = (addressType: AddressType, formData: AddressForm) => {
    if (addressType === AddressTypeEnum.BILLING) {
      billingAddressForm.value = formData;
    } else if (addressType === AddressTypeEnum.SHIPPING) {
      shippingAddressForm.value = formData;

      if (isBillingAddressSameAsShipping.value) {
        billingAddressForm.value = { ...shippingAddressForm.value };
      }
    }
  };

  const saveSelectedShippingAddressAsBilling = async () => {
    if (isBillingAddressSameAsShipping.value) {
      const address = cartAddressToCartShippingAddressInput(shippingAddresses.value[0]);

      await saveSelectedBillingAddress(address);
    }
  };

  const submitAddresses = async () => {
    if (!areDataCorrect.value) {
      showValidationMessage.value = true;
      return;
    }

    if (!isAuthenticated.value) {
      if (isShippingFormVisible.value) {
        await saveShippingAddressForm(shippingAddressForm.value);
      }

      if (isBillingFormVisible.value && !isBillingAddressSameAsShipping.value) {
        await saveBillingAddressForm(billingAddressForm.value, isBillingAddressSameAsShipping.value && !hasOnlyBooxiItemsInCart.value);
      }

      showValidationMessage.value = false;
      checkoutStore.setAddressFormVisibility(AddressTypeEnum.BILLING, !isBillingAddressSameAsShipping.value);
    } else {
      if (isShippingFormVisible.value) {
        await addAddress(addressFormToAddressInput(shippingAddressForm.value, AddressTypeEnum.SHIPPING, true));
      }

      if (isBillingFormVisible.value && !isBillingAddressSameAsShipping.value) {
        await addAddress(addressFormToAddressInput(billingAddressForm.value, AddressTypeEnum.BILLING, true));
      }

      showValidationMessage.value = false;
    }
  };

  return {
    isBillingSectionValid,
    shippingAddressForm,
    billingAddressForm,
    isBillingAddressSameAsShipping,
    setAddressSectionValidationStatus,
    toggleIsBillingAddressSameAsShipping,
    updateAddressForm,
    saveSelectedShippingAddressAsBilling,
    submitAddresses,
    areDataCorrect,
    showValidationMessage,
    isLoading: computed(() => isSaveUserAddressLoading.value || isSaveGuestAddressLoading.value),
    isShippingFormVisible,
    isBillingFormVisible,
    isShippingMethodsVisible
  };
}
