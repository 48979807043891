import { useUser } from '@checkout/composables';
import type { GuestUserForm } from '@checkout-organisms/auth/guest-user-form/models';
import type { Field } from '@form-generator';
import { useFields } from '@form-generator';
import { computed, ref } from '@nuxtjs/composition-api';

export const useGuestUserForm = () => {
  const { userEmail } = useUser();

  const formData = ref<GuestUserForm>({
    email: userEmail.value || ''
  });

  const { extendField, getEmailField } = useFields();

  const formFields = computed<Field[]>(() => [
    extendField(getEmailField('email'), {
      attributes: {
        class: 'input--with-label',
        autocomplete: 'username'
      }
    })
  ]);

  return {
    formData,
    formFields
  };
};
