import type { FetchResult } from '@apollo/client';
import type { ApolloQueryResult } from '@apollo/client/core';

export class ApiService<ApiMethods> {
  protected api: ApiMethods;

  constructor(api: ApiMethods) {
    this.api = api;
  }

  protected async handleApiResponse<T>(apiCallPromise: Promise<FetchResult<T> | ApolloQueryResult<T>>): Promise<T> {
    try {
      const result = await apiCallPromise;
      return this.processApiResponse(result);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  private processApiResponse<T>(result: FetchResult<T>): T {
    const { data, errors } = result;

    if (errors && errors.length) {
      const joinedErrors = errors.map((e) => e.message).join('| ');
      throw new Error(joinedErrors);
    }

    if (!data) {
      throw new Error('No data received from API');
    }

    return data;
  }
}
