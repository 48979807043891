


























import { defineComponent, ref } from '@nuxtjs/composition-api';
import { SfLoader } from '@storefront-ui/vue';

export default defineComponent({
  name: 'OrderSummaryTemplate',
  components: { SfLoader },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const isCollapsed = ref<boolean>(false);
    const toggleCollapse = (): void => {
      isCollapsed.value = !isCollapsed.value;
    };

    return {
      toggleCollapse,
      isCollapsed
    };
  }
});
