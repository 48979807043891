import { useCart, useMagentoState } from '@checkout/composables';
import { useAsyncMethods } from '@checkout/composables/core';
import { UserService } from '@checkout/services';
import { useUserStore } from '@checkout/stores';
import type { ForgotPasswordForm } from '@checkout-organisms/auth/forgot-password-form/models';
import type { GuestUserForm } from '@checkout-organisms/auth/guest-user-form/models';
import type { UserLoginForm } from '@checkout-organisms/auth/login-form/models';
import type { CreateUserForm } from '@checkout-organisms/auth/registration-form/models';
import { computed, useContext, useRoute } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';

import type { Customer } from '~/composables';
import { useUiNotification } from '~/composables';
import { useGoogleTagManager } from '~/diptyqueTheme/composable';
import type { User } from '~/models';
import { useCustomerStore } from '~/modules/customer/stores/customer';

import type { UseUser, UseUserAsyncMethods } from './useUser.model';

export function useUser(): UseUser {
  const {
    app,
    i18n,
    app: { $gtm }
  } = useContext();

  const route = useRoute();
  const { getLoginDetails } = useGoogleTagManager();

  const { mergeCarts, updateCartPartially, cart, errors: cartErrors, clearCart } = useCart();
  const { getCustomerToken, setCustomerToken } = useMagentoState();
  const userStore = useUserStore();
  const customerStore = useCustomerStore();
  const userService = new UserService(app);

  const { send: sendNotification } = useUiNotification();

  const setUserData = (data: User) => {
    userStore.setUser(data);
  };

  const { methods, errors, loading } = useAsyncMethods<UseUserAsyncMethods>({
    composableName: 'useUser',
    methodsFactory: () => ({
      loadUser: async () => {
        if (!getCustomerToken()) {
          return;
        }

        const { customer } = await userService.loadUser();

        // Workaround to sync stores
        customerStore.user = customer as unknown as Customer;
        setUserData(customer);
      },
      login: async (credentials: UserLoginForm, isAccountCreation = false) => {
        const { generateCustomerToken } = await userService.login(credentials);

        // Workaround to sync stores and avoid interceptor problems (plugins/token-expired.ts)
        customerStore.setIsLoggedIn(true);
        customerStore.setIsTokenExpired(false);
        setCustomerToken(generateCustomerToken?.token, credentials.remember_me);

        await methods.loadUser();

        if (errors.loadUser.value) {
          return;
        }

        await mergeCarts();

        if (cartErrors.mergeCarts.value) {
          clearCart();
          return;
        }

        userStore.setLoginStatus(true);

        sendNotification({
          id: Symbol(),
          message: i18n.t('common.notifications.carts_are_merged') as string,
          type: 'success',
          persist: false
        });

        $gtm.push(getLoginDetails(route.value, isAccountCreation));
      },
      register: async (user: CreateUserForm) => {
        const { email, password, optin_sms, remember_me, optin_mail, optin_newsletter } = user;

        await userService.register({
          firstname: ' ',
          lastname: ' ',
          email,
          password,
          // Since the backend requires a mobile phone when "By SMS and Phone" is selected, we need to provide dummy phone number.
          ...(optin_sms
            ? {
                optin_sms,
                mobile: '0000000000'
              }
            : {}),
          optin_mail,
          optin_newsletter
        });

        await methods.login({ email, password, remember_me }, true);
      },
      resetPassword: async (data: ForgotPasswordForm) => {
        await userService.resetPassword(data);
      },
      setGuestEmail: async (data: GuestUserForm) => {
        const {
          setGuestEmailOnCart: { cart: responseCart }
        } = await userService.setGuestEmailOnCart({
          cart_id: cart.value.id,
          ...data
        });

        updateCartPartially(responseCart);
        userStore.setGuestUserFlag(true);
      },
      checkIsEmailAvailable: async (email: string) => {
        const {
          isEmailAvailable: { is_email_available }
        } = await userService.checkIsEmailAvailable(email);

        return is_email_available;
      }
    })
  });

  const { userInfo } = storeToRefs(userStore);
  const userEmail = computed(() => userInfo.value?.email || cart.value.email);

  return {
    ...methods,
    setUserData,
    errors,
    loading,
    userEmail
  };
}
