
























import { useShippingAddress, useUserAddress } from '@checkout/composables';
import { useAddressTransformer } from '@checkout/composables/useAddressTransformer';
import { useCartStore, useCheckoutStore } from '@checkout/stores';
import { useAddressSection } from '@checkout-organisms/shipping-step/_internal/address-section/composables/useAddressSection';
import { useShippingStep } from '@checkout-organisms/shipping-step/composables/useShippingStep';
import type { AddressForm } from '@checkout-organisms/shipping-step/models';
import type { PropType } from '@nuxtjs/composition-api';
import { computed, defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';

import type { Address, AddressType } from '~/models';
import { AddressTypeEnum } from '~/models';

export default defineComponent({
  name: 'AddressSection',
  components: {
    AddressesList: () =>
      import('@checkout-organisms/shipping-step/_internal').then(
        (m) => m.AddressesList
      ),
    ShippingAddressForm: () =>
      import('@checkout-organisms/shipping-step/_internal').then(
        (m) => m.ShippingAddressForm
      )
  },
  props: {
    formData: {
      type: Object as PropType<AddressForm>,
      required: true
    },
    addressType: {
      type: String as PropType<AddressType>,
      required: true
    },
    isLoading: {
      type: Boolean
    }
  },
  emits: ['validate', 'update:form-data', 'submit'],
  setup(props, { emit }) {
    const {
      activeAddressId,
      hideFormAddressAutocomplete,
      addresses,
      getAddressFormFieldsSequence,
      isFormVisible
    } = useAddressSection(props.addressType);
    const { setDefaultAddress } = useUserAddress();
    const checkoutStore = useCheckoutStore();
    const { isBillingAddressFormVisible, isShippingAddressFormVisible } =
      storeToRefs(checkoutStore);
    const { toggleIsBillingAddressSameAsShipping } = useShippingStep();
    const {
      saveSelectedShippingAddress,
      saveSelectedBillingAddress,
      loading: isSaveAddressLoading
    } = useShippingAddress();
    const { addressToCartAddressInput } = useAddressTransformer();
    const cartStore = useCartStore();
    const { cartId, mergeCartsFlag } = storeToRefs(cartStore);

    const internalFormData = ref<AddressForm>(props.formData);

    watch(internalFormData, (newValue) => {
      emit('update:form-data', newValue);
    });

    const setActiveAddress = async (address: Address) => {
      if (
        !cartId.value ||
        mergeCartsFlag.value ||
        isSaveAddressLoading.saveSelectedShippingAddress.value ||
        isSaveAddressLoading.saveSelectedBillingAddress.value
      )
        return;

      const transformedAddress = addressToCartAddressInput(address);

      await Promise.all([
        setDefaultAddress(address.id, props.addressType),
        props.addressType === AddressTypeEnum.SHIPPING
          ? saveSelectedShippingAddress(transformedAddress)
          : saveSelectedBillingAddress(transformedAddress)
      ]);

      emit('validate', true);
    };

    watch(
      () => isSaveAddressLoading.saveSelectedShippingAddress.value,
      (newVal) => cartStore.setCartLoadingFlag(newVal)
    );

    watch(
      [activeAddressId, cartId, mergeCartsFlag],
      async () => {
        if (activeAddressId.value && cartId.value) {
          const addressToSet = addresses.value.find(
            (addr) => addr.id === activeAddressId.value
          );

          if (addressToSet) {
            await setActiveAddress(addressToSet);
          }
        }
      },
      { immediate: true }
    );

    // validate guest user data
    const handleFormValidation = (value: boolean) => {
      emit('validate', value);
    };

    const isActionButtonVisible = computed<boolean>(
      () =>
        props.addressType === AddressTypeEnum.BILLING &&
        !isShippingAddressFormVisible.value &&
        isBillingAddressFormVisible.value
    );

    return {
      setActiveAddress,
      activeAddressId,
      handleFormValidation,
      hideFormAddressAutocomplete,
      internalFormData,
      addresses,
      isFormVisible,
      getAddressFormFieldsSequence,
      AddressTypeEnum,
      isActionButtonVisible,
      toggleIsBillingAddressSameAsShipping
    };
  }
});
