import { useAsyncMethods } from '@checkout/composables/core';
import { CountriesService } from '@checkout/services';
import { useCountriesStore } from '@checkout/stores/countries/countries.store';
import { computed, useContext } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';

import type { CountryInterface, TransformedCountry } from '~/models';
import { CountryCodeEnum } from '~/models';

import type { UseCountries, UseCountriesMethods } from './useCountries.model';

export const useCountries = (): UseCountries => {
  const { app } = useContext();
  const countriesService = new CountriesService(app);

  const countriesStore = useCountriesStore();
  const { allowedCountries, allCountries } = storeToRefs(countriesStore);

  const storeCode = computed(() => app.i18n.localeProperties.code);
  const defaultCountryCode = computed<CountryCodeEnum | string>(() => {
    switch (storeCode.value) {
      case 'en_us':
        return CountryCodeEnum.Us;
      case 'fr_eu':
        return CountryCodeEnum.Fr;
      case 'fr_fr':
        return CountryCodeEnum.Fr;
      case 'ja_jp':
        return CountryCodeEnum.Jp;
      case 'en_hk':
        return CountryCodeEnum.Hk;
      case 'zh_hk':
        return CountryCodeEnum.Hk;
      default:
        return '';
    }
  });

  const transformCountry = (country: CountryInterface): TransformedCountry => {
    return {
      id: country.id,
      label: country.full_name_locale,
      englishLabel: country.full_name_english,
      abbreviation: country.two_letter_abbreviation,
      availableRegions: country.available_regions || []
    };
  };

  const {
    methods: methodsWithExecute,
    loading,
    errors,
    overallLoading
  } = useAsyncMethods<UseCountriesMethods>({
    composableName: 'useCountries',
    methodsFactory: () => ({
      getCountries: async (): Promise<TransformedCountry[]> => {
        if (allCountries.value.length) {
          return allCountries.value;
        }
        const { countries } = await countriesService.getCountries();
        const transformedCountries = countries.map((country) => transformCountry(country));

        countriesStore.setAllCountries(transformedCountries);

        return transformedCountries;
      },

      getAllowedCountries: async (): Promise<TransformedCountry[]> => {
        if (allowedCountries.value.store === app.i18n.localeProperties.code && allowedCountries.value.countries.length) {
          return allowedCountries.value.countries;
        }
        const { getAllowedCounries: getAllowedCountries } = await countriesService.getAllowedCountries();
        const transformedCountries = getAllowedCountries.map((country) => transformCountry(country));

        countriesStore.setAllowedCountries(app.i18n.localeProperties.code, transformedCountries);

        return transformedCountries;
      },

      getCountryById: async (id: string): Promise<CountryInterface> => {
        const { country } = await countriesService.getCountryById(id);

        return country || ({} as CountryInterface);
      }
    })
  });

  return {
    ...methodsWithExecute,
    errors,
    loading,
    overallLoading,
    defaultCountryCode,
    allCountries,
    allowedCountries: computed(() => allowedCountries.value.countries)
  };
};
