



















import { defineComponent } from '@nuxtjs/composition-api';

import { useShippingComplete } from './composables/useShippingComplete';

export default defineComponent({
  name: 'ShippingComplete',
  setup() {
    const { selectedShippingAddress, selectedShippingMethod } =
      useShippingComplete();

    return {
      selectedShippingAddress,
      selectedShippingMethod
    };
  }
});
