



























































































import {
  BoutiqueSelector,
  LoadingOverlay,
  ShippingMethodItem
} from '@checkout-organisms/shipping-provider/_internal';
import type { ShippingMethodCategory } from '@checkout-organisms/shipping-provider/models/shipping-method-category';
import type { PropType } from '@nuxtjs/composition-api';
import { computed, defineComponent } from '@nuxtjs/composition-api';

import type { AvailableShippingMethod, Boutique } from '~/models';

export default defineComponent({
  name: 'ShippingMethodsList',
  components: {
    LoadingOverlay,
    ShippingMethodItem,
    BoutiqueSelector
  },
  props: {
    shippingMethods: {
      type: Array as PropType<AvailableShippingMethod[]>,
      default: () => []
    },
    sortedShippingMethods: {
      type: Array as PropType<ShippingMethodCategory[]>,
      default: () => []
    },
    categoriesEnabled: {
      type: Boolean
    },
    selectedMethod: {
      type: Object as PropType<AvailableShippingMethod>,
      default: null
    },
    isLoading: {
      type: Boolean
    },
    clickAndCollectMethodName: {
      type: String,
      required: true
    },
    boutiquesList: {
      type: Array as PropType<Boutique[]>,
      default: () => []
    },
    selectedBoutique: {
      type: Object as PropType<Boutique>,
      default: null
    },
    toggleBoutiquesList: {
      type: Boolean
    }
  },
  emits: ['select-method', 'select-boutique', 'toggle-boutiques'],
  setup(props) {
    const isNoAvailableShippingMethods = computed<boolean>(() => {
      return (
        (props.categoriesEnabled && !props.sortedShippingMethods.length) ||
        !props.shippingMethods.length
      );
    });

    return {
      isNoAvailableShippingMethods
    };
  }
});
