
















































import { useUser } from '@checkout/composables';
import RequiredFieldsNote from '@checkout-atoms/required-fields-note/RequiredFieldsNote.vue';
import { FormGenerator } from '@form-generator';
import { defineComponent } from '@nuxtjs/composition-api';

import { useLoginForm } from './composables';

export default defineComponent({
  name: 'UserLoginForm',
  components: { RequiredFieldsNote, FormGenerator },
  emits: ['open-forgot-password-form', 'cancel'],
  setup(props, { emit }) {
    const {
      formFields,
      formData,
      isEmailRecognized,
      showEmailRecognitionMessage
    } = useLoginForm();

    const {
      login,
      loading: { login: loading }
    } = useUser();

    const onFormSubmit = async () => {
      await login({ ...formData.value });
    };

    const openForgotPasswordForm = () => {
      emit('open-forgot-password-form');
    };

    const cancel = () => {
      emit('cancel');
    };

    return {
      formData,
      formFields,
      onFormSubmit,
      loading,
      openForgotPasswordForm,
      cancel,
      isEmailRecognized,
      showEmailRecognitionMessage
    };
  }
});
