import { useAuth, useUserAddress } from '@checkout/composables';
import { useAddressTransformer } from '@checkout/composables/useAddressTransformer';
import { getAddressFormFieldsSequence } from '@checkout/helpers/get-address-form-fields-sequence.helper';
import { useCartStore } from '@checkout/stores';
import { useCheckoutStore } from '@checkout/stores/checkout.store';
import { computed, onBeforeMount, onMounted, watch } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';

import type { Address, AddressType, CartShippingAddress } from '~/models';
import { AddressTypeEnum } from '~/models';

export function useAddressSection(addressType: AddressType) {
  const { isAuthenticated } = useAuth();
  const cartStore = useCartStore();
  const { shippingAddresses: cartShippingAddress, billingAddress: cartBillingAddress } = storeToRefs(cartStore);
  const checkoutStore = useCheckoutStore();
  const { billingAddressId, shippingAddressId, isBillingAddressSameAsShipping } = storeToRefs(checkoutStore);
  const {
    billingAddresses: userBillingAddresses,
    allowedShippingAddresses: userShippingAddresses,
    loading: { addAddress: addAddressLoading },
    getAddresses
  } = useUserAddress();
  const { cartAddressToAddress } = useAddressTransformer();

  const addresses = computed<Address[]>(() => {
    if (!isAuthenticated.value) {
      const shipping =
        cartShippingAddress.value?.map((address: CartShippingAddress) => cartAddressToAddress(address, AddressTypeEnum.SHIPPING)) || [];
      const billing = cartBillingAddress.value ? [cartAddressToAddress(cartBillingAddress.value, AddressTypeEnum.BILLING)] : [];

      return addressType === AddressTypeEnum.BILLING ? billing : shipping;
    }

    return addressType === AddressTypeEnum.BILLING ? userBillingAddresses.value ?? [] : userShippingAddresses.value ?? [];
  });

  const isFormVisible = computed(() => {
    return !addresses.value?.length;
  });

  const hideFormAddressAutocomplete = computed(() => addressType === AddressTypeEnum.BILLING);

  const defaultAddressPropertyMap = {
    [AddressTypeEnum.SHIPPING]: 'default_shipping',
    [AddressTypeEnum.BILLING]: 'default_billing'
  };

  const activeAddressId = computed(() => {
    const selectedAddressId = addressType === AddressTypeEnum.BILLING ? billingAddressId.value : shippingAddressId.value;

    if (selectedAddressId) {
      return selectedAddressId;
    }

    const defaultAddressProperty = defaultAddressPropertyMap[addressType];

    return addresses.value?.find((address) => address?.[defaultAddressProperty])?.id;
  });

  const updateAddressFormVisibility = () => {
    if (addressType === AddressTypeEnum.SHIPPING || !isBillingAddressSameAsShipping.value) {
      checkoutStore.setAddressFormVisibility(addressType, addresses.value?.length === 0);
    }
  };

  onBeforeMount(async () => {
    if (isAuthenticated.value && !addresses.value?.length) {
      await getAddresses();
    }
  });

  onMounted(() => {
    updateAddressFormVisibility();
  });

  watch(
    addresses,
    () => {
      updateAddressFormVisibility();
    },
    { immediate: true }
  );

  return {
    activeAddressId,
    hideFormAddressAutocomplete,
    addAddressLoading,
    isFormVisible,
    addresses,
    getAddressFormFieldsSequence
  };
}
