import { useAuth, useCountries, useShippingAddress, useUserAddress } from '@checkout/composables';
import { useAddressTransformer } from '@checkout/composables/useAddressTransformer';
import { getEmptyUserAddressForm } from '@checkout/helpers';
import { useCheckoutStore } from '@checkout/stores';
import type { AddressForm } from '@checkout-organisms/shipping-step/_internal/shipping-address-form/models';
import { onBeforeUnmount, onMounted, ref, watch } from '@nuxtjs/composition-api';
import isEqual from 'lodash/isEqual';
import { useGoogleAutocomplete } from 'molecules/GoogleAutocomplete/composables';
import { storeToRefs } from 'pinia';

import { useStore } from '~/composables';
import type { Address, AddressType, CountryCodeEnum } from '~/models';
import { AddressTypeEnum } from '~/models';

export function useUpdateAddress() {
  // External
  const { addressToAddressForm, addressFormToAddressInput } = useAddressTransformer();
  const { saveShippingAddressForm, saveBillingAddressForm, overallLoading: isSaveGuestAddressLoading } = useShippingAddress();
  const { addAddress, updateAddress, overallLoading: isSaveUserAddressLoading } = useUserAddress();
  const { isJpStore } = useStore();
  const { defaultCountryCode } = useCountries();
  const { isAuthenticated } = useAuth();
  const { observer: googleSuggestionsObserver, setupObserver, resetObserver, attachGoogleDropdownListener } = useGoogleAutocomplete();
  const checkoutStore = useCheckoutStore();
  const { isBillingAddressSameAsShipping } = storeToRefs(checkoutStore);

  const emptyAddressForm: AddressForm = getEmptyUserAddressForm(isJpStore.value, defaultCountryCode.value as CountryCodeEnum);

  // Ref
  const isEditAddressOpened = ref(false);
  const editableAddress = ref<AddressForm | null>(null);
  const editableAddressId = ref<number | null>(null);
  const editableInitialAddress = ref<AddressForm | null>(null);
  const isAddressChanged = ref<boolean>(false);
  const isNewAddress = ref<boolean>(false);

  // Methods
  const setEditableAddress = (address: Address) => {
    isEditAddressOpened.value = true;
    editableAddressId.value = address.id || null;
    editableInitialAddress.value = addressToAddressForm(address);
    editableAddress.value = addressToAddressForm(address);
    isAddressChanged.value = false;
  };

  const resetForm = () => {
    editableAddress.value = {
      ...emptyAddressForm,
      country_code: editableAddress.value.country_code
    };
  };

  const handleNewAddress = () => {
    resetForm();
    isNewAddress.value = true;
  };

  const handleCloseEdit = () => {
    isEditAddressOpened.value = false;
    isNewAddress.value = false;
    editableAddressId.value = null;
    editableAddress.value = null;
  };

  const handleSubmitAddressForm = async (form: AddressForm, addressType: AddressType): Promise<Address | null> => {
    if (isAuthenticated.value) {
      const isDefaultAddress = addressType === AddressTypeEnum.BILLING ? form.default_billing : form.default_shipping;
      const address = addressFormToAddressInput(form, addressType, isDefaultAddress);

      if (isNewAddress.value) {
        return addAddress(address);
      } else if (editableAddressId.value) {
        await Promise.all([
          updateAddress(editableAddressId.value, address),
          addressType === AddressTypeEnum.BILLING ? saveBillingAddressForm(form, isBillingAddressSameAsShipping.value) : saveShippingAddressForm(form)
        ]);
      }
    } else {
      addressType === AddressTypeEnum.BILLING
        ? await saveBillingAddressForm(form, isBillingAddressSameAsShipping.value)
        : await saveShippingAddressForm(form);
    }

    return null;
  };

  watch(editableAddress, (newVal: AddressForm) => {
    if (isEditAddressOpened.value) {
      isAddressChanged.value = !isEqual(newVal, editableInitialAddress.value);
    }
  });

  // Google suggestions
  watch(isEditAddressOpened, (newVal) => {
    if (newVal) {
      // When edit modal opens
      setupObserver();
      attachGoogleDropdownListener(); // Initial check

      // Set up repeated checks for the first second (as Google dropdown may appear with delay)
      // eslint-disable-next-line no-magic-numbers
      const checkInterval = setInterval(attachGoogleDropdownListener, 200) as unknown as number;
      setTimeout(() => clearInterval(checkInterval), 1000);
    } else {
      // When edit modal closes
      if (googleSuggestionsObserver) {
        resetObserver();
      }
    }
  });

  onMounted(() => {
    if (isEditAddressOpened.value) {
      setupObserver();
      attachGoogleDropdownListener();
    }
  });

  onBeforeUnmount(() => {
    if (googleSuggestionsObserver) {
      resetObserver();
    }
  });

  return {
    isEditAddressOpened,
    isNewAddress,
    editableAddress,
    isAddressChanged,
    isLoading: isSaveUserAddressLoading || isSaveGuestAddressLoading,
    setEditableAddress,
    handleNewAddress,
    handleCloseEdit,
    handleSubmitAddressForm
  };
}
