







import { defineComponent } from '@nuxtjs/composition-api';

import VaimoLoader from '~/diptyqueTheme/components/atoms/VaimoLoader.vue';

export default defineComponent({
  name: 'LoadingOverlay',
  components: {
    VaimoLoader
  },
  props: {
    message: {
      type: String,
      required: true
    }
  }
});
