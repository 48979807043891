




import { useUser } from '@checkout/composables';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'UserInfo',
  setup() {
    const { userEmail } = useUser();

    return {
      userEmail
    };
  }
});
