import { ApiService } from '@checkout/services/core';
import type { CartApiMethods } from '@checkout/services/models';
import type { NuxtAppOptions } from '@nuxt/types';
import type { ApiClientMethods, CustomQuery } from '@vue-storefront/core';

import getCustomerCartId from '~/diptyqueTheme/customQueries/magento/customerCartId';
import type { CartQuery, CreateEmptyCartMutation, MergeCartsInput, MergeCartsMutation, UserCartIdQuery, UserCartQuery } from '~/models';

export class CartService extends ApiService<ApiClientMethods<CartApiMethods>> {
  constructor(context: NuxtAppOptions) {
    super(context.$vsf.$magento.api);
  }

  mergeCarts(params: MergeCartsInput, customQuery?: CustomQuery): Promise<MergeCartsMutation> {
    return this.handleApiResponse(this.api.mergeCarts(params, customQuery));
  }

  getUserCartId(): Promise<UserCartIdQuery> {
    return this.handleApiResponse(this.api.customQuery({ query: getCustomerCartId }));
  }

  getCart(cartId: string): Promise<CartQuery> {
    return this.handleApiResponse(this.api.cart(cartId));
  }

  getCustomerCart(): Promise<UserCartQuery> {
    return this.handleApiResponse(this.api.customerCart());
  }

  createEmptyCart(customQuery?: CustomQuery): Promise<CreateEmptyCartMutation> {
    return this.handleApiResponse(this.api.createEmptyCart(customQuery));
  }
}
