// eslint-disable-next-line no-duplicate-imports
import { defineStore } from 'pinia';

import type { AddressMutation, User } from '~/models';
import { AddressTypeEnum } from '~/models';

import type { UserState } from './userState.model';

export const useUserStore = defineStore('user-v2', {
  state: (): UserState => ({
    user: null,
    isUserLoggedIn: false,
    isUserUpdatingFlag: false,
    isUserLogoutFlag: false,
    isUserLoadingFlag: false,
    isGuestUser: false,
    isUserPasswordChangingFlag: false,
    isUserRegisterFlag: false,
    isUserLoginFlag: false,
    setGuestEmailOnCartFlag: false,
    checkIsEmailAvailableFlag: false,
    isResetPasswordFlag: false
  }),

  persist: true,

  actions: {
    setUser(user: User): void {
      this.user = { ...user };
    },

    setUserAddresses(addresses: AddressMutation[]): void {
      this.user = {
        addresses
      };
    },

    setUserAddress(address: AddressMutation): void {
      if (!this.user) {
        return;
      }
      const index = this.user.addresses.findIndex(({ id }) => id === address.id);

      if (index >= 0) {
        this.user.addresses.splice(index, 1, address);
      } else {
        this.user.addresses = [...this.user.addresses, address];
      }
    },

    setLoginStatus(status: boolean): void {
      this.isUserLoggedIn = status;
    },

    setUserUpdateFlag(flag: boolean): void {
      this.isUserUpdatingFlag = flag;
    },

    setUserLogOutFlag(flag: boolean): void {
      this.isUserLogoutFlag = flag;
    },

    setUserLoadFlag(flag: boolean): void {
      this.isUserLoadingFlag = flag;
    },

    setUserPasswordChangeFlag(flag: boolean): void {
      this.isUserPasswordChangingFlag = flag;
    },

    setUserRegisterFlag(flag: boolean): void {
      this.isUserRegisterFlag = flag;
    },

    setUserLoginFlag(flag: boolean) {
      this.isUserLoginFlag = flag;
    },

    setGuestEmailCartFlag(flag: boolean) {
      this.setGuestEmailOnCartFlag = flag;
    },

    setGuestUserFlag(flag: boolean) {
      this.isGuestUser = flag;
    },

    setCheckIsEmailAvailableFlag(flag: boolean) {
      this.checkIsEmailAvailableFlag = flag;
    },

    setResetPasswordFlag(flag: boolean) {
      this.isResetPasswordFlag = flag;
    },

    pathUser<K extends keyof User>(key: K, data: User[K]): void {
      if (!this.user) {
        this.user = {} as User;
      }
      this.user[key] = data;
    },

    resetUser() {
      this.user = null;
    }
  },

  getters: {
    userInfo: (state): User => state.user,
    isLoggedIn: (state): boolean => state.isUserLoggedIn,
    userRegisterFlag: (state): boolean => state.isUserRegisterFlag,
    addresses: (state): AddressMutation[] => state.user?.addresses || [],
    billingAddresses(): AddressMutation[] {
      return this.addresses.filter((address) => address.address_book_type === AddressTypeEnum.BILLING) || [];
    },
    shippingAddresses(): AddressMutation[] {
      return this.addresses.filter((address) => address.address_book_type === AddressTypeEnum.SHIPPING) || [];
    }
  }
});
