import { ApiService } from '@checkout/services/core';
import type { UserApiMethods } from '@checkout/services/models';
import type { NuxtAppOptions } from '@nuxt/types';
import type { ApiClientMethods, CustomQuery } from '@vue-storefront/core';

import isEmailAvailableQuery from '~/diptyqueTheme/customQueries/magento/isEmailAvailable.gql';
import type {
  AddressInput,
  CheckIsEmailAvailableQuery,
  CreateCustomerAddressMutation,
  CreateUserInput,
  CreateUserMutation,
  DeleteCustomerAddressMutation,
  GenerateUserTokenMutation,
  LoginUserInput,
  PasswordResetInput,
  ResetPasswordMutation,
  SetGuestEmailOnCartInput,
  SetGuestEmailOnCartMutation,
  UpdateCustomerAddressMutation,
  UserAddressesQuery,
  UserQuery
} from '~/models';

export class UserService extends ApiService<ApiClientMethods<UserApiMethods>> {
  constructor(context: NuxtAppOptions) {
    super(context.$vsf.$magento.api);
  }

  loadUser(customQuery: CustomQuery = { customer: 'customerWithOptions' }): Promise<UserQuery> {
    return this.handleApiResponse(this.api.customer(customQuery));
  }

  login(credentials: LoginUserInput): Promise<GenerateUserTokenMutation> {
    return this.handleApiResponse(this.api.generateCustomerToken(credentials));
  }

  register(registrationData: CreateUserInput): Promise<CreateUserMutation> {
    return this.handleApiResponse(this.api.createCustomer(registrationData));
  }

  resetPassword(data: PasswordResetInput): Promise<ResetPasswordMutation> {
    return this.handleApiResponse(this.api.requestPasswordResetEmail(data));
  }

  getAddresses(customQuery: CustomQuery = { getCustomerAddresses: 'customerAddresses' }): Promise<UserAddressesQuery> {
    return this.handleApiResponse(this.api.getCustomerAddresses(customQuery));
  }

  createAddress(address: AddressInput): Promise<CreateCustomerAddressMutation> {
    return this.handleApiResponse(this.api.createCustomerAddress(address));
  }

  deleteAddress(addressId: number): Promise<DeleteCustomerAddressMutation> {
    return this.handleApiResponse(this.api.deleteCustomerAddress(addressId));
  }

  updateAddress(addressId: number, input: AddressInput): Promise<UpdateCustomerAddressMutation> {
    return this.handleApiResponse(this.api.updateCustomerAddress({ addressId, input }));
  }

  setGuestEmailOnCart(data: SetGuestEmailOnCartInput): Promise<SetGuestEmailOnCartMutation> {
    return this.handleApiResponse(this.api.setGuestEmailOnCart(data));
  }

  checkIsEmailAvailable(email: string): Promise<CheckIsEmailAvailableQuery> {
    return this.handleApiResponse(
      this.api.customQuery({
        query: isEmailAvailableQuery,
        queryVariables: {
          email
        }
      })
    );
  }
}
