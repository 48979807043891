var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"margins-container",attrs:{"id":"checkout"}},[_c('VaimoButton',{attrs:{"variant":"primary","label-centered":true},on:{"click":_vm.triggerError}},[_vm._v("\n    Trigger Error\n  ")]),_vm._v(" "),_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"main-section"},[_c('Stepper',{scopedSlots:_vm._u([{key:"user-account",fn:function(ref){
var isActive = ref.isActive;
var activateNextStep = ref.activateNextStep;
return [_c('AuthStep',{attrs:{"is-active":isActive},on:{"next":activateNextStep}})]}},{key:"shipping",fn:function(ref){
var isActive = ref.isActive;
var activateNextStep = ref.activateNextStep;
return [_c('Shipping',{attrs:{"is-active":isActive},on:{"next":activateNextStep}})]}},{key:"payment",fn:function(ref){
var isActive = ref.isActive;
return [(isActive)?_c('PaymentStep'):_vm._e()]}}])})],1),_vm._v(" "),_c('div',{staticClass:"side-section"},[_c('OrderSummary'),_vm._v(" "),_c('ReinsuranceList')],1)]),_vm._v(" "),_c('NostoCart',{attrs:{"cart":_vm.cart}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }