







































import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'DeliveryDateTimeSelector',
  components: {
    VaimoMultiselect: () => import('atoms/VaimoMultiselect.vue')
  },
  props: {
    deliveryDate: {
      type: String,
      default: ''
    },
    deliveryTime: {
      type: String,
      default: ''
    },
    dateFormat: {
      type: String,
      default: 'YYYY-MM-DD'
    },
    isDateUnavailable: {
      type: Function,
      default: () => false
    },
    isTimeEnabled: {
      type: Boolean
    },
    timeOptions: {
      type: Array,
      default: () => []
    }
  },
  emits: ['update:delivery-date', 'update:delivery-time']
});
