import { computed, ref } from '@nuxtjs/composition-api';
import { defineStore } from 'pinia';

import { useConfig } from '~/composables';
import type { BooxiLifeTimeInterface } from '~/diptyqueTheme/composable/useBooxi/types';
import { isVirtualCartItem } from '~/diptyqueTheme/helpers/cart/booxi';
import { parseStringToDate } from '~/helpers/dateHelpers';
import type { Cart, CartBillingAddress, CartShippingAddress } from '~/models';

export const INITIAL_CART_STORE_STATE: Cart = { id: '', is_virtual: false, total_quantity: 0, shipping_addresses: [], available_payment_methods: [] };

export const useCartStore = defineStore(
  'cart-v2',
  () => {
    const cart = ref<Cart>(INITIAL_CART_STORE_STATE);

    const { config } = useConfig();

    const isDataLoading = ref(false);
    const isCartLoading = ref(false);
    const isBooxiLoading = ref(false);
    const initialLoading = ref(true);
    const isItemAdding = ref(false);

    const cartClearFlag = ref(false);
    const loadTotalQtyFlag = ref(false);
    const removeItemFlag = ref(false);
    const updateItemQuantityFlag = ref(false);
    const updateEngravingItemFlag = ref(false);
    const applyCouponFlag = ref(false);
    const mergeCartsFlag = ref(false);

    const cartData = computed(() => cart.value);
    const cartId = computed(() => cart.value.id);
    const totalQuantity = computed(() => cart.value.total_quantity);
    const shippingAddresses = computed<CartShippingAddress[]>(() => cart.value.shipping_addresses);
    const billingAddress = computed<CartBillingAddress>(() => cart.value.billing_address);
    const isCartVirtual = computed(() => cart.value.is_virtual);

    const setCart = (newCart: Cart) => {
      cart.value = hasOnlyBooxiItemsInCart.value ? { ...newCart, shipping_addresses: shippingAddresses.value } : { ...newCart };
    };

    const pathCart = <K extends keyof Cart>(key: K, data: Cart[K]) => {
      cart.value[key] = data;
    };

    const setCartLoadingFlag = (flag: boolean) => {
      isCartLoading.value = flag;
    };

    const setCartInitialLoadingFlag = (flag: boolean) => {
      initialLoading.value = flag;
    };

    const setCartClearFlag = (flag: boolean) => {
      cartClearFlag.value = flag;
    };

    const setLoadTotalQtyFlag = (flag: boolean) => {
      loadTotalQtyFlag.value = flag;
    };

    const setRemoveItemFlag = (flag: boolean) => {
      removeItemFlag.value = flag;
    };

    const setUpdateItemQuantityFlag = (flag: boolean) => {
      updateItemQuantityFlag.value = flag;
    };

    const setEngravingUpdateItemFlag = (flag: boolean) => {
      updateEngravingItemFlag.value = flag;
    };

    const setApplyCouponFlag = (flag: boolean) => {
      applyCouponFlag.value = flag;
    };

    const setMergeCartsFlag = (flag: boolean) => {
      mergeCartsFlag.value = flag;
    };

    const setIsBooxiLoadingFlag = (flag: boolean) => {
      isBooxiLoading.value = flag;
    };

    const setShippingMethodOnVirtualProductsInCart = (address: CartShippingAddress) => {
      cart.value.shipping_addresses = [address];
    };

    const resetCart = () => {
      cart.value = {
        id: '',
        is_virtual: false,
        total_quantity: 0,
        shipping_addresses: []
      };
    };

    const hasOnlyBooxiItemsInCart = computed(() => {
      const items = cartData.value.items;
      const booxiItems = items?.filter(isVirtualCartItem);

      return items?.length === booxiItems?.length;
    });

    const hasSomeBooxiItemInCart = () => {
      const items = cartData.value.items;

      return items.some(isVirtualCartItem);
    };

    const expirationBooxiItemTime = computed(() => {
      const item = cartData.value?.items?.find((product) => product.booxi_item_info.is_booxi && product.booxi_item_info.expired_time !== 'false');
      if (item?.booxi_item_info) {
        return parseStringToDate(item.booxi_item_info.expired_time);
      }
      return null;
    });

    const checkIsBooxiExpired = () => {
      const hasExpiredBooking = cartData.value.booxi_booking_info?.cart_has_expired_booking;
      const isBooxiItemExpired = expirationBooxiItemTime.value && new Date() >= expirationBooxiItemTime.value;

      return hasExpiredBooking || isBooxiItemExpired;
    };

    const booxiLifeTimeData = computed((): BooxiLifeTimeInterface => {
      return {
        isAutoCloseEnabled: config.value.booxi_appointment_scheduling_auto_close_enabled,
        lifetime: config.value.booxi_appointment_scheduling_auto_close_time
      };
    });

    return {
      cart,
      isDataLoading,
      isCartLoading,
      isBooxiLoading,
      initialLoading,
      isItemAdding,
      cartClearFlag,
      loadTotalQtyFlag,
      removeItemFlag,
      updateItemQuantityFlag,
      updateEngravingItemFlag,
      applyCouponFlag,
      mergeCartsFlag,
      cartData,
      cartId,
      totalQuantity,
      shippingAddresses,
      billingAddress,
      isCartVirtual,
      booxiLifeTimeData,
      hasOnlyBooxiItemsInCart,
      setCart,
      pathCart,
      setCartLoadingFlag,
      setCartInitialLoadingFlag,
      setCartClearFlag,
      setLoadTotalQtyFlag,
      setRemoveItemFlag,
      setUpdateItemQuantityFlag,
      setEngravingUpdateItemFlag,
      setApplyCouponFlag,
      setMergeCartsFlag,
      setIsBooxiLoadingFlag,
      setShippingMethodOnVirtualProductsInCart,
      resetCart,
      hasSomeBooxiItemInCart,
      checkIsBooxiExpired
    };
  },
  { persist: { paths: ['cart'] } }
);
