import { useStore } from '~/composables';

export const getAddressFormFieldsSequence = (): string[] => {
  const { isJpStore, isUsCaStore, isZhHkStore } = useStore();

  if (isJpStore.value) {
    return [
      'firstname',
      'lastname',
      'firstnamekana',
      'lastnamekana',
      'postcode',
      'region_code',
      'city',
      'street',
      'apartment',
      'telephone',
      'default_shipping',
      'default_billing'
    ];
  }

  if (isUsCaStore.value) {
    return [
      'country_code',
      'firstname',
      'lastname',
      'street',
      'apartment',
      'postcode',
      'city',
      'region_code',
      'telephone',
      'default_shipping',
      'default_billing'
    ];
  }

  if (isZhHkStore.value) {
    return [
      'firstname',
      'lastname',
      'street',
      'apartment',
      'city',
      'telephone',
      'postcode',
      'region_code',
      'country_code',
      'default_shipping',
      'default_billing'
    ];
  }

  // Default sequence if no matching store condition
  return [
    'firstname',
    'lastname',
    'firstnamekana',
    'lastnamekana',
    'street',
    'apartment',
    'postcode',
    'city',
    'region_id',
    'country_code',
    'telephone',
    'default_shipping',
    'default_billing'
  ];
};
