import { useAsyncMethods, useCart, usePayment } from '@checkout/composables';
import { PaymentService } from '@checkout/services';
import { useUserStore } from '@checkout/stores';
import { ref, useContext, useRouter } from '@nuxtjs/composition-api';

import { useUiNotification } from '~/composables';
import type { GmoPaymentMethod, GmoPaymentMethodsConfig, GmoStoredPaymentMethod } from '~/models';
import { useCart as useOldCart } from '~/modules/core/composables/useCart';

export const useGmoPayment = () => {
  const router = useRouter();
  const { app, localePath } = useContext();

  const { cart, clearCart } = useCart();

  const { setCart: setOldCart } = useOldCart();
  const userStore = useUserStore();
  const { setCustomerOrderData } = usePayment();
  const { send: sendNotification } = useUiNotification();

  const paymentLoading = ref(false);

  const onGmoBeforePay = () => {
    paymentLoading.value = true;
  };

  const onGmoAfterPay = async (orderNumber: string) => {
    setCustomerOrderData();
    app.$vsf.$magento.config.state.removeCartId();
    clearCart();
    setOldCart(null);
    userStore.resetUser();
    paymentLoading.value = false;
    await router.push(
      app.localeRoute({
        name: 'thank-you',
        query: {
          order: orderNumber
        }
      })
    );
  };
  const onGmoError = (error: string) => {
    paymentLoading.value = false;
    sendNotification({
      id: Symbol('gmo_credit_card_error'),
      message: error,
      persist: false,
      type: 'danger',
      icon: 'cross'
    });
  };

  const paymentService = new PaymentService(app);

  const paymentMethods = ref<GmoPaymentMethod[]>([]);
  const storedPaymentMethods = ref<GmoStoredPaymentMethod[]>([]);
  const paymentMethodsConfig = ref<GmoPaymentMethodsConfig>(null);

  const {
    methods,
    errors,
    loading: asyncMethodsLoadings
  } = useAsyncMethods({
    composableName: 'useGmoPayment',
    methodsFactory: () => ({
      getPaymentMethods: async () => {
        const { gmoPaymentMethods } = await paymentService.getGmoPaymentMethods({ cart_id: cart.value.id });

        paymentMethods.value = gmoPaymentMethods?.paymentMethods || [];
        storedPaymentMethods.value = gmoPaymentMethods?.storedPaymentMethods || [];
        paymentMethodsConfig.value = gmoPaymentMethods?.config;

        return gmoPaymentMethods?.paymentMethods;
      },
      getCustomerPaymentTokens: async () => {
        const { gmoCustomerPaymentTokens } = await paymentService.getGmoCustomerPaymentTokens();

        return gmoCustomerPaymentTokens?.items;
      },
      deletePaymentToken: async (hash: string) => {
        const result = await paymentService.deletePaymentToken({ public_hash: hash });

        return result.gmoDeletePaymentToken?.customerPaymentTokens.items;
      },
      loadPaymentStatus: async (orderNumber: string) => {
        const { gmoPaymentStatus } = await paymentService.getGmoPaymentStatus({ order_number: orderNumber });

        return gmoPaymentStatus;
      },

      verify3dSecure: async (accessId: string) => {
        const {
          gmoPaymentThreeDSecureReturn: { success, orderNumber, message }
        } = await paymentService.verifyGmo3dSecure({ access_id: accessId });

        if (success) {
          await onGmoAfterPay(orderNumber);
          return;
        }

        onGmoError(message);
        await router.push(localePath('/'));
      }
    })
  });

  return {
    onGmoBeforePay,
    onGmoAfterPay,
    onGmoError,

    paymentMethods,
    storedPaymentMethods,
    paymentMethodsConfig,

    ...methods,
    errors,
    loading: {
      ...asyncMethodsLoadings,
      gmoPayment: paymentLoading
    }
  };
};
