









































import RequiredFieldsNote from '@checkout-atoms/required-fields-note/RequiredFieldsNote.vue';
import { useForgotPasswordForm } from '@checkout-organisms/auth/forgot-password-form/composables';
import { FormGenerator } from '@form-generator';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ForgotPasswordForm',
  components: {
    RequiredFieldsNote,
    FormGenerator,
    VaimoIcon: () => import('atoms/VaimoIcon.vue')
  },
  setup() {
    const { formData, formFields, onSubmit, submitLoading, isFormSubmitted } =
      useForgotPasswordForm();

    return {
      formData,
      formFields,
      onSubmit,
      submitLoading,
      isFormSubmitted
    };
  }
});
