








import {
  defineComponent,
  ref,
  useContext,
  useFetch
} from '@nuxtjs/composition-api';

import contentfulEntriesIds from '~/diptyqueTheme/config/contentfulEntries';
import { useContentfulGQL } from '~/integrations/contentful/src/composables/useContentfulGQL';

import { ReinsuranceItem } from './_internal';
import type { ReinsuranceItemModel } from './models';

export default defineComponent({
  name: 'ReinsuranceList',
  components: {
    ReinsuranceItem
  },
  setup() {
    const { query, queryResponse } = useContentfulGQL('checkout-reinsurance');
    const {
      app: { i18n }
    } = useContext();
    const items = ref<ReinsuranceItemModel[]>([]);

    useFetch(async () => {
      await query('getCheckoutReinsurance', {
        id: contentfulEntriesIds.CHECKOUT_REINSURANCE,
        locale: i18n.localeProperties.contentfulLocale
      });

      items.value =
        queryResponse.value?.checkoutReinsurance?.itemsCollection?.items ?? [];
    });

    return { items };
  }
});
