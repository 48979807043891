import { render, staticRenderFns } from "./JpSuccessCheckoutTotals.vue?vue&type=template&id=3c0dec3c&scoped=true&"
import script from "./JpSuccessCheckoutTotals.vue?vue&type=script&lang=ts&"
export * from "./JpSuccessCheckoutTotals.vue?vue&type=script&lang=ts&"
import style0 from "./JpSuccessCheckoutTotals.vue?vue&type=style&index=0&id=3c0dec3c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c0dec3c",
  null
  
)

export default component.exports