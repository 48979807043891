var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-login-form"},[_c('RequiredFieldsNote',{staticClass:"user-login-form__required-fields-note"}),_vm._v(" "),_c('FormGenerator',{attrs:{"fields":_vm.formFields,"is-submit-button-disabled":_vm.loading || !_vm.isEmailRecognized || !_vm.showEmailRecognitionMessage,"submit-button-text":_vm.$t('Log in')},on:{"submit":_vm.onFormSubmit},scopedSlots:_vm._u([{key:"after-remember_me",fn:function(){return [_c('button',{staticClass:"user-login-form__forgot-password",attrs:{"type":"button"},on:{"click":_vm.openForgotPasswordForm}},[_vm._v("\n        "+_vm._s(_vm.$t('Forgotten password?'))+"\n      ")])]},proxy:true},{key:"email-error",fn:function(ref){
var errorMessage = ref.errorMessage;
return [(!errorMessage && _vm.showEmailRecognitionMessage)?_c('div',{staticClass:"user-login-form__message",class:("user-login-form__message--" + (_vm.isEmailRecognized ? 'success' : 'notification')),attrs:{"aria-label":("" + (_vm.$t(
          _vm.isEmailRecognized
            ? 'Email address found'
            : 'This email is new! Create an account and enjoy multiple benefits'
        )))}},[_c('VaimoIcon',{attrs:{"icon":_vm.isEmailRecognized ? 'check' : 'cross',"size":12}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t(
            _vm.isEmailRecognized
              ? 'Email address found'
              : 'This email is new! Create an account and enjoy multiple benefits'
          )))])],1):_vm._e()]}}]),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }