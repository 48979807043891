import type { AvailableRegion, CartAddressRegion, Region } from '~/models';

export function transformAvailableRegionToRegion(availableRegion: AvailableRegion): Region {
  return {
    region: availableRegion.name,
    region_code: availableRegion.code,
    region_id: Number(availableRegion.id)
  };
}

export function transformCartAddressRegionToRegion(cartAddressRegion: CartAddressRegion): Region {
  return {
    region: cartAddressRegion.label,
    region_code: cartAddressRegion.code,
    region_id: cartAddressRegion.region_id
  };
}
