import { useShippingAddress, useUser } from '@checkout/composables';
import { getEmptyUserAddressForm } from '@checkout/helpers';
import type { AddressForm } from '@checkout-organisms/shipping-step/models';
import { computed, ref } from '@nuxtjs/composition-api';
import { defineStore } from 'pinia';

import { useStore } from '~/composables';
import type { AddressType } from '~/models';
import { AddressTypeEnum } from '~/models';

export const useCheckoutStore = defineStore('onePageCheckout', () => {
  const { isJpStore } = useStore();
  const emptyAddressForm: AddressForm = getEmptyUserAddressForm(isJpStore.value);
  const { userEmail } = useUser();
  const { availableShippingAddresses } = useShippingAddress();

  // State
  const state = computed(() => ({
    'user-account': userEmail.value,
    shipping: availableShippingAddresses.value?.[0] || null,
    payment: null
  }));

  const isPageLoading = ref<boolean>(true);
  const isBillingAddressSameAsShipping = ref<boolean>(true);
  const shippingAddressId = ref<number | null>(null);
  const billingAddressId = ref<number | null>(null);
  const guestShippingAddress = ref<AddressForm>(emptyAddressForm);
  const guestBillingAddress = ref<AddressForm>(emptyAddressForm);
  const paymentMethodCode = ref<string>(null);
  const isShippingAddressFormVisible = ref<boolean>(false);
  const isBillingAddressFormVisible = ref<boolean>(false);

  // Actions
  const setPageLoading = (isLoading: boolean) => {
    isPageLoading.value = isLoading;
  };

  const setIsBillingAddressSameAsShipping = (value: boolean) => {
    isBillingAddressSameAsShipping.value = value;
  };

  const setShippingAddressId = (id: number) => {
    shippingAddressId.value = id;
  };
  const setBillingAddressId = (id: number) => {
    billingAddressId.value = id;
  };
  const setGuestShippingAddress = (address: AddressForm) => {
    guestShippingAddress.value = address;
  };
  const setGuestBillingAddress = (address: AddressForm) => {
    guestBillingAddress.value = address;
  };

  const setPaymentMethodCode = (code: string) => {
    paymentMethodCode.value = code;
  };

  const setAddressFormVisibility = (addressType: AddressType, value: boolean) => {
    if (addressType === AddressTypeEnum.BILLING) {
      isBillingAddressFormVisible.value = value;
    } else {
      isShippingAddressFormVisible.value = value;
    }
  };

  // Getters
  const isAddressFormsVisible = computed<boolean>(() => {
    return isShippingAddressFormVisible.value && isBillingAddressFormVisible.value;
  });

  const isOnlyShippingAddressFormVisible = computed<boolean>(() => {
    return isShippingAddressFormVisible.value && !isBillingAddressFormVisible.value;
  });

  const isOnlyBillingAddressFormVisible = computed<boolean>(() => {
    return !isShippingAddressFormVisible.value && isBillingAddressFormVisible.value;
  });

  const shippingData = computed(() => state.value.shipping);

  const selectedShippingMethodCode = computed(() => state.value.shipping?.selected_shipping_method?.method_code || null);

  return {
    state,
    shippingData,
    isPageLoading,
    isBillingAddressSameAsShipping,
    shippingAddressId,
    billingAddressId,
    guestShippingAddress,
    guestBillingAddress,
    selectedShippingMethodCode,
    paymentMethodCode,
    isShippingAddressFormVisible,
    isBillingAddressFormVisible,
    isAddressFormsVisible,
    isOnlyShippingAddressFormVisible,
    isOnlyBillingAddressFormVisible,
    setIsBillingAddressSameAsShipping,
    setShippingAddressId,
    setBillingAddressId,
    setGuestShippingAddress,
    setGuestBillingAddress,
    setPaymentMethodCode,
    setPageLoading,
    setAddressFormVisibility
  };
});
