import { ApiService } from '@checkout/services/core';
import type { NuxtAppOptions } from '@nuxt/types';
import type { CustomQuery } from '@vue-storefront/core';

import isValidJpDeliveryDate from '~/diptyqueTheme/customQueries/magento/isValidJpDeliveryDate.gql';
import setDeliveryDateOnShipping from '~/diptyqueTheme/customQueries/magento/setDeliveryDateOnShipping';
import type {
  CheckoutApiMethods,
  GetIsValidJpDeliveryDateInput,
  GetIsValidJpDeliveryDateQuery,
  SetClickAndCollectShopInput,
  SetClickAndCollectShopMutation,
  SetDeliveryDateOnShippingInput,
  SetDeliveryDateOnShippingMutation,
  SetShippingMethodsOnCartInput,
  SetShippingMethodsOnCartMutation
} from '~/models';
import type { ApiClientMethods } from '~/types/core';

export class ShippingMethodsService extends ApiService<ApiClientMethods<CheckoutApiMethods>> {
  constructor(context: NuxtAppOptions) {
    super(context.$vsf.$magento.api);
  }

  setShippingMethods(
    input: SetShippingMethodsOnCartInput,
    customQuery: CustomQuery = { setShippingMethodsOnCart: 'customSetShippingMethodsOnCart' }
  ): Promise<SetShippingMethodsOnCartMutation> {
    return this.handleApiResponse(this.api.setShippingMethodsOnCart(input, customQuery));
  }

  setDeliveryDateTime(input: SetDeliveryDateOnShippingInput): Promise<SetDeliveryDateOnShippingMutation> {
    return this.handleApiResponse(
      this.api.customQuery({
        query: setDeliveryDateOnShipping,
        queryVariables: {
          input
        }
      })
    );
  }

  getIsValidJpDeliveryDate(input: GetIsValidJpDeliveryDateInput): Promise<GetIsValidJpDeliveryDateQuery> {
    return this.handleApiResponse(
      this.api.customQuery({
        query: isValidJpDeliveryDate,
        queryVariables: input
      })
    );
  }

  setClickAndCollectShop(input: SetClickAndCollectShopInput): Promise<SetClickAndCollectShopMutation> {
    return this.handleApiResponse(this.api.setClickAndCollectShop(input));
  }
}
