





























import { defineComponent } from '@nuxtjs/composition-api';
import { SfImage } from '@storefront-ui/vue';

export default defineComponent({
  name: 'ReinsuranceItem',
  components: {
    SfImage
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    icon: {
      type: Object as () => { url: string; title: string },
      default: () => ({ url: '', title: '' })
    },
    phone: {
      type: String,
      default: ''
    },
    isOpeningHours: {
      type: Boolean,
      default: false
    }
  }
});
