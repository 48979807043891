import { useAnchor, useAuth } from '@checkout/composables';
import { STEPS, steps } from '@checkout/constants';
import type { StepKey, StepState } from '@checkout/models';
import { computed, reactive } from '@nuxtjs/composition-api';

import { validateStep } from '../../helpers/validateStep';
import { useCheckoutStore } from '../../stores';

export const useCheckoutStep = () => {
  const { activeAnchor, setAnchor } = useAnchor();
  const checkoutStore = useCheckoutStore();
  const DEFAULT_STEP: StepKey = STEPS.USER_ACCOUNT;
  const activeStep = computed(() => (validateStep(activeAnchor.value) ? (activeAnchor.value as StepKey) : DEFAULT_STEP));
  const defaultStepState: StepState = {
    expanded: false,
    disabled: true,
    static: false
  };
  const state = reactive<Record<StepKey, StepState>>({
    [STEPS.USER_ACCOUNT]: { ...defaultStepState },
    [STEPS.SHIPPING]: { ...defaultStepState },
    [STEPS.PAYMENT]: { ...defaultStepState }
  });

  const setStepState = (key: StepKey, { expanded, disabled, isStatic }: Omit<StepState, 'static'> & { isStatic: boolean }) => {
    state[key].expanded = expanded;
    state[key].disabled = disabled;
    state[key].static = isStatic;
  };

  const activateStep = (key: StepKey) => {
    setStepState(key, { expanded: true, disabled: false, isStatic: false });
    setAnchor(key);
  };

  const deactivateStep = (key: StepKey = activeStep.value) => {
    setStepState(key, { expanded: false, disabled: false, isStatic: true });
  };

  const disableStep = (key: StepKey) => {
    setStepState(key, { expanded: false, disabled: true, isStatic: false });
  };

  const activateNextStepFunc = (idx: number) => {
    if (idx < 0 || idx >= steps.length - 1) return;

    deactivateStep(steps[idx]);
    activateStep(steps[idx + 1]);
  };

  const { isAuthenticated } = useAuth();
  const isLoggedLoginStep = (step: StepKey) => {
    return step === STEPS.USER_ACCOUNT && isAuthenticated.value;
  };

  const toggleStepFunc = (step: StepKey) => {
    if (isLoggedLoginStep(step) || activeStep.value === step) return;

    if (checkoutStore.state[activeStep.value]) {
      deactivateStep(activeStep.value);
    } else {
      disableStep(activeStep.value);
    }

    activateStep(step);
  };

  if (activeStep.value === DEFAULT_STEP) {
    activateStep(DEFAULT_STEP);
  }

  return { activeStep, activateStep, disableStep, deactivateStep, state, activateNextStepFunc, toggleStepFunc, isLoggedLoginStep };
};
