







































import { useAuth, useUpdateAddress } from '@checkout/composables';
import { getAddressFormFieldsSequence } from '@checkout/helpers/get-address-form-fields-sequence.helper';
import SideModal from '@checkout-atoms/side-modal/SideModal.vue';
import type { AddressForm } from '@checkout-organisms/shipping-step/_internal/shipping-address-form/models';
import type { PropType } from '@nuxtjs/composition-api';
import {
  computed,
  defineComponent,
  useContext,
  watch
} from '@nuxtjs/composition-api';

import type { AddressType } from '~/models';
import type { Address } from '~/models';

export default defineComponent({
  name: 'EditAddressModal',
  components: {
    SideModal,
    SfButton: () => import('@storefront-ui/vue').then((m) => m.SfButton),
    ShippingAddressForm: () =>
      import('@checkout-organisms/shipping-step/_internal').then(
        (m) => m.ShippingAddressForm
      )
  },
  props: {
    addressType: {
      type: String as PropType<AddressType>,
      required: true
    },
    showModal: {
      type: Boolean,
      default: false
    },
    initialAddress: {
      type: Object as PropType<Address | null>,
      default: null
    }
  },
  emits: ['close', 'submit'],
  setup(props, { emit }) {
    const { isAuthenticated } = useAuth();
    const { i18n } = useContext();
    const {
      isEditAddressOpened,
      editableAddress,
      isNewAddress,
      isLoading,
      isAddressChanged,
      setEditableAddress,
      handleNewAddress,
      handleCloseEdit,
      handleSubmitAddressForm
    } = useUpdateAddress();

    const showAddNewAddressButton = computed<boolean>(
      () => isAuthenticated.value && !isNewAddress.value
    );

    const editAddressModalTitle = computed<string>(() => {
      return isNewAddress.value
        ? (i18n.t(
            `checkout.shipping.add_new_address.title.${props.addressType}`
          ) as string)
        : (i18n.t(
            `checkout.shipping.edit_address.title.${props.addressType}`
          ) as string);
    });

    watch(
      () => props.initialAddress,
      (newAddress) => {
        if (newAddress && props.showModal) {
          setEditableAddress(newAddress);
        }
      },
      { immediate: true }
    );

    const handleClose = () => {
      handleCloseEdit();
      emit('close');
    };

    const handleSubmit = async (
      form: AddressForm,
      addressType: AddressType
    ) => {
      if (isAddressChanged.value) {
        const address = await handleSubmitAddressForm(form, addressType);
        handleClose();
        if (address) emit('submit', address);
      } else {
        handleClose();
      }
    };

    return {
      isEditAddressOpened,
      editableAddress,
      isAuthenticated,
      editAddressModalTitle,
      isNewAddress,
      isLoading,
      showAddNewAddressButton,
      handleNewAddress,
      handleClose,
      handleSubmit,
      getAddressFormFieldsSequence
    };
  }
});
