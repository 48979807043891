












































import { useBoutique, useCart, useShippingMethod } from '@checkout/composables';
import { useCartStore, useCheckoutStore } from '@checkout/stores';
import {
  DeliveryDateTimeSelector,
  ShippingMethodsList
} from '@checkout-organisms/shipping-provider/_internal';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch
} from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import { storeToRefs } from 'pinia';

import { useStore } from '~/composables';
import { Logger } from '~/helpers/logger';
import type { AvailableShippingMethod } from '~/models';

const CLICK_AND_COLLECT_METHOD_NAME = 'expressclickandcollect';

export default defineComponent({
  name: 'ShippingProvider',
  components: {
    ShippingMethodsList,
    DeliveryDateTimeSelector,
    SfButton
  },
  emits: ['to-payment'],
  setup(_, { emit }) {
    const { isJpStore } = useStore();
    const { cart } = useCart();

    const cartStore = useCartStore();
    const { isCartLoading: isShippingMethodsLoading, hasOnlyBooxiItemsInCart } =
      storeToRefs(cartStore);
    const checkoutStore = useCheckoutStore();
    const { shippingData } = storeToRefs(checkoutStore);

    const {
      errors: { saveSelectedShippingMethod: saveSelectedShippingMethodError },
      shippingMethods,
      selectedShippingMethod,
      deliveryDateInfo,
      getEstimatedDeliveryDates,
      loading: {
        saveSelectedShippingMethod: isSaveSelectedShippingMethodLoading,
        saveDeliveryDateTime: isSaveDeliveryDateTimeLoading
      },
      sortedShippingMethods,
      getShippingCategoriesEnabled,
      isDeliveryDateUnavailable,
      isShippingMethodStepCompleted,
      selectShippingMethod: handleShippingMethodSelection,
      saveDeliveryDateTime,
      jpDateFormat,
      jpDeliveryTimeList,
      isCartSaving,
      loadJpDeliveryDateData
    } = useShippingMethod();

    const {
      boutiquesList,
      saveSelectedBoutique,
      selectedBoutique,
      toggleBoutiquesList,
      setSelectedBoutique,
      getFormatedDistance
    } = useBoutique();

    const isTogglingBoutiquesList = ref(false);
    const deliveryDate = ref<string>('');
    const deliveryTime = ref<string>('');

    const dateFormatString = computed<string>(() => {
      return jpDateFormat.value || 'YYYY-MM-DD';
    });

    const restrictedShippingMethodMessage = computed<string | undefined>(
      () =>
        shippingMethods.value?.find((method) => method.error_message)
          ?.error_message
    );

    const isDateSelectorEnabled = computed<boolean>(() => {
      if (isJpStore.value) {
        return true;
      }
      return (
        !!deliveryDateInfo.value?.enableDate &&
        deliveryDateInfo.value?.enableDate !== '0'
      );
    });

    const isTimeSelectorEnabled = computed<boolean>(() => {
      if (isJpStore.value && jpDeliveryTimeList.value.length > 0) {
        return true;
      }
      return (
        !!deliveryDateInfo.value?.enableTime &&
        deliveryDateInfo.value?.enableTime !== '0'
      );
    });

    const deliveryTimeOptions = computed(() => {
      if (isJpStore.value && jpDeliveryTimeList.value.length > 0) {
        return jpDeliveryTimeList.value;
      }
      return deliveryDateInfo.value?.deliveryTime || [];
    });

    const shouldShowLoadingOverlay = computed(() => {
      return (
        (isShippingMethodsLoading.value ||
          isSaveSelectedShippingMethodLoading.value ||
          isCartSaving.value) &&
        !isTogglingBoutiquesList.value
      );
    });

    const isProceedToPaymentDisabled = computed(() => {
      return (
        !isShippingMethodStepCompleted.value ||
        isSaveDeliveryDateTimeLoading.value ||
        isSaveSelectedShippingMethodLoading.value ||
        isShippingMethodsLoading.value ||
        isCartSaving.value
      );
    });

    const isShippingMethodsListVisible = computed<boolean>(() => {
      return (
        !hasOnlyBooxiItemsInCart.value &&
        (shippingMethods.value.length > 0 || !isShippingMethodsLoading.value)
      );
    });

    const toggleBoutiques = () => {
      isTogglingBoutiquesList.value = true;
      toggleBoutiquesList.value = !toggleBoutiquesList.value;
      // Reset the flag after a delay to ensure the toggle operation completes
      setTimeout(() => {
        isTogglingBoutiquesList.value = false;
        // eslint-disable-next-line no-magic-numbers
      }, 300);
    };

    const selectShippingMethod = async (method: AvailableShippingMethod) => {
      // Skip if selecting the already selected method
      if (selectedShippingMethod.value?.method_code === method.method_code) {
        return;
      }

      // Reset delivery date/time when selecting a new shipping method
      deliveryDate.value = '';
      deliveryTime.value = '';

      if (!method) return;

      await handleShippingMethodSelection(method);

      // If it's a JP store, try to load delivery date data
      if (isJpStore.value) {
        loadJpDeliveryDateData(method.carrier_code);
      }

      // Select first boutique if click and collect is selected
      if (
        !saveSelectedShippingMethodError.value &&
        method.method_code === CLICK_AND_COLLECT_METHOD_NAME &&
        boutiquesList.value &&
        boutiquesList.value.length > 0
      ) {
        setSelectedBoutique(boutiquesList.value[0]);
      }
    };

    const updateDeliveryDate = (value: string) => {
      deliveryDate.value = value;
    };
    const updateDeliveryTime = (value: string) => {
      deliveryTime.value = value;
    };

    const handleFormSubmit = async () => {
      if (
        selectedShippingMethod.value?.method_code ===
          CLICK_AND_COLLECT_METHOD_NAME &&
        selectedBoutique.value
      ) {
        await saveSelectedBoutique(selectedBoutique.value);
      }

      if (isJpStore.value) {
        await saveDeliveryDateTime(deliveryDate.value, deliveryTime.value);
      }

      return true;
    };

    const onContinueToPayment = async () => {
      const isValid = await handleFormSubmit();

      if (isValid) {
        emit('to-payment');
      }
    };

    watch(
      [cart, isShippingMethodsLoading],
      async () => {
        if (
          cart.value?.id &&
          cart.value?.shipping_addresses?.length > 0 &&
          !hasOnlyBooxiItemsInCart.value &&
          !isShippingMethodsLoading.value
        ) {
          try {
            // Update estimated delivery dates when cart updates
            const countryCode =
              cart.value?.shipping_addresses[0]?.country?.code || '';
            await getEstimatedDeliveryDates(cart.value?.id, countryCode);

            // For JP store, also load delivery date data if shipping method is selected
            if (isJpStore.value && selectedShippingMethod.value?.carrier_code) {
              loadJpDeliveryDateData(selectedShippingMethod.value.carrier_code);
            }
          } catch (error) {
            Logger.error('Error updating estimated delivery dates:', error);
          }
        }
      },
      { deep: true }
    );

    return {
      CLICK_AND_COLLECT_METHOD_NAME,
      boutiquesList,
      dateFormatString,
      deliveryDate,
      deliveryDateInfo,
      deliveryTime,
      deliveryTimeOptions,
      selectedBoutique,
      shippingMethods,
      toggleBoutiquesList,
      isDateSelectorEnabled,
      isDeliveryDateUnavailable,
      isSaveDeliveryDateTimeLoading,
      isSaveSelectedShippingMethodLoading,
      isShippingMethodStepCompleted,
      isShippingMethodsLoading,
      isTogglingBoutiquesList,
      isTimeSelectorEnabled,
      isProceedToPaymentDisabled,
      isShippingMethodsListVisible,
      restrictedShippingMethodMessage,
      saveSelectedShippingMethodError,
      selectedShippingMethod,
      shouldShowLoadingOverlay,
      sortedShippingMethods,
      isCartSaving,
      getFormatedDistance,
      getShippingCategoriesEnabled,
      onContinueToPayment,
      selectShippingMethod,
      setSelectedBoutique,
      toggleBoutiques,
      updateDeliveryDate,
      updateDeliveryTime
    };
  }
});
