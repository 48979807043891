















import { defineComponent } from '@nuxtjs/composition-api';
import { SfSidebar } from '@storefront-ui/vue';

export default defineComponent({
  name: 'SideModal',
  components: {
    SfSidebar
  },
  props: {
    value: {
      type: Boolean
    },
    heading: {
      type: String,
      default: ''
    },
    cancelButtonText: {
      type: String,
      default: ''
    }
  },
  emits: ['input'],
  setup(props, { emit }) {
    const close = () => {
      emit('input', false);
    };

    return {
      close
    };
  }
});
