















































import { useCheckoutStore } from '@checkout/stores';
import { useAddressForm } from '@checkout-organisms/shipping-step/_internal/shipping-address-form/composables';
import { FormGenerator } from '@form-generator';
import type { PropType } from '@nuxtjs/composition-api';
import { computed, defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';

import type { AddressType } from '~/models';
import { AddressTypeEnum } from '~/models';

import type { AddressForm } from './models';

export default defineComponent({
  name: 'ShippingAddressForm',
  components: {
    FormGenerator,
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    SfButton: () => import('@storefront-ui/vue').then((m) => m.SfButton)
  },
  props: {
    formId: {
      type: String,
      default: ''
    },
    addressType: {
      type: String as PropType<AddressType>,
      required: true
    },
    value: {
      type: Object as PropType<AddressForm | null>,
      default: null
    },
    fieldsSequence: {
      type: Array as PropType<(keyof AddressForm)[]>,
      default: () => []
    },
    hideActionButtons: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    hasDefaultAddressCheckbox: {
      type: Boolean
    },
    isEdit: {
      type: Boolean
    }
  },
  emits: ['submit', 'cancel', 'input', 'validate', 'close'],
  setup(props, { emit }) {
    const { formData, fields, isTelephoneFieldValid } = useAddressForm(props);
    const checkoutStore = useCheckoutStore();
    const {
      isAddressFormsVisible,
      isOnlyShippingAddressFormVisible,
      isOnlyBillingAddressFormVisible
    } = storeToRefs(checkoutStore);

    const isFormValid = ref<boolean>(false);
    const isErrorMessageVisible = ref<boolean>(false);

    const validateForm = () => {
      const isValid = isFormValid.value && isTelephoneFieldValid.value;

      if (isFormValid.value !== isValid) {
        isFormValid.value = isValid;
      }

      emit('validate', isValid);

      return isValid;
    };

    const handleFormInput = (value: AddressForm) => {
      isErrorMessageVisible.value = false;
      emit('input', value);
    };

    const handleSubmit = () => {
      const isValid = validateForm();

      if (!isValid) {
        isErrorMessageVisible.value = true;
        return;
      }

      try {
        emit('submit', formData.value);
      } catch (error) {
        isErrorMessageVisible.value = true;
      }
    };

    const handleCloseForm = () => {
      emit('close');
    };

    const handleFormValidation = (formIsValid: boolean) => {
      isFormValid.value = formIsValid;
      validateForm();
    };

    watch(
      () => props.value,
      (newValue) => {
        if (
          newValue &&
          JSON.stringify(newValue) !== JSON.stringify(formData.value)
        ) {
          formData.value = { ...newValue };
        } else if (!newValue) {
          formData.value = null;
        }
      },
      { deep: true }
    );

    watch(formData, (val) => {
      emit('input', val);
    });

    watch(isTelephoneFieldValid, () => {
      validateForm();
    });

    const isInfoTextVisible = computed<boolean>(
      () =>
        (props.addressType === AddressTypeEnum.SHIPPING &&
          isAddressFormsVisible.value) ||
        isOnlyShippingAddressFormVisible.value ||
        isOnlyBillingAddressFormVisible.value ||
        props.isEdit
    );

    const isSubmitDisabled = computed<boolean>(() => {
      return props.isLoading || !isFormValid.value;
    });

    return {
      formData,
      fields,
      isFormValid,
      isErrorMessageVisible,
      handleFormValidation,
      handleFormInput,
      handleSubmit,
      handleCloseForm,
      isInfoTextVisible,
      isSubmitDisabled
    };
  }
});
