import { useAuth, useMagentoState } from '@checkout/composables';
import { useAsyncMethods } from '@checkout/composables/core';
import type { UseCart } from '@checkout/composables/useCart/useCart.model';
import { CartService } from '@checkout/services';
import { INITIAL_CART_STORE_STATE, useCartStore } from '@checkout/stores';
import { useContext } from '@nuxtjs/composition-api';
import merge from 'lodash.merge';
import { storeToRefs } from 'pinia';

import type { Cart } from '~/models';
import { useCartStore as useOldCartStore } from '~/modules/core/stores/cart';
import type { Cart as OldCart } from '~/modules/GraphQL/types';

export function useCart(): UseCart {
  const { app } = useContext();
  const { isAuthenticated } = useAuth();
  const cartStore = useCartStore();
  const oldCartStore = useOldCartStore();
  const { cart, hasOnlyBooxiItemsInCart, shippingAddresses } = storeToRefs(cartStore);

  const magentoState = useMagentoState();
  const cartService = new CartService(app);

  const clearCart = () => {
    cartStore.setCart(INITIAL_CART_STORE_STATE);
  };

  const updateCartPartially = (cartPart: Partial<Cart>) => {
    cartStore.setCart(merge(hasOnlyBooxiItemsInCart.value ? { ...cart.value, shipping_addresses: shippingAddresses.value } : cart.value, cartPart));
  };

  const { methods, errors, loading } = useAsyncMethods({
    composableName: 'useCart',
    methodsFactory: () => ({
      mergeCarts: async () => {
        const currentCartId: string = magentoState.getCartId();
        const {
          customerCart: { id }
        } = await cartService.getUserCartId();

        if (id && currentCartId && currentCartId !== id) {
          cartStore.setMergeCartsFlag(true);

          const { mergeCarts } = await cartService.mergeCarts({ sourceCartId: currentCartId, destinationCartId: id });
          if (errors.mergeCarts.value) {
            clearCart();
            cartStore.setMergeCartsFlag(false);
            return;
          }

          cartStore.setCart(mergeCarts);
          magentoState.setCartId(mergeCarts.id);
          cartStore.setMergeCartsFlag(false);

          oldCartStore.$patch((state) => {
            state.cart = mergeCarts as unknown as OldCart;
          });
        } else {
          clearCart();
          oldCartStore.$patch((state) => {
            state.cart = null;
          });
        }
      },
      createCart: async () => {
        const { createEmptyCart: cartId } = await cartService.createEmptyCart();
        magentoState.setCartId(cartId);

        return cartId;
      },
      loadCart: async () => {
        cartStore.setCartLoadingFlag(true);

        if (isAuthenticated.value) {
          const { customerCart } = await cartService.getCustomerCart();
          magentoState.setCartId(customerCart.id);
          cartStore.setCart(customerCart);
        } else {
          let cartId = magentoState.getCartId();
          if (!cartId) {
            cartId = await methods.createCart();
          }

          const { cart } = await cartService.getCart(cartId);
          magentoState.setCartId(cartId);
          cartStore.setCart(cart);
        }

        cartStore.setCartLoadingFlag(false);
      }
    })
  });

  return {
    cart,
    ...methods,
    errors,
    loading,
    clearCart,
    updateCartPartially
  };
}
